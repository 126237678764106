import axios from "axios";
import config from "../config";
import { setAuthKeys } from "../auth";

export const INCREMENT_LOOP_COUNT_REQUEST = "INCREMENT_LOOP_COUNT_REQUEST";
export const INCREMENT_LOOP_COUNT_SUCCESS = "INCREMENT_LOOP_COUNT_SUCCESS";
export const INCREMENT_LOOP_COUNT_FAILURE = "INCREMENT_LOOP_COUNT_FAILURE";

export const POST_CLIP_REQUEST = "POST_CLIP_REQUEST";
export const POST_CLIP_SUCCESS = "POST_CLIP_SUCCESS";
export const POST_CLIP_FAILURE = "POST_CLIP_FAILURE";

export const GET_TRENDING_CLIPS_REQUEST = "GET_TRENDING_CLIPS_REQUEST";
export const GET_TRENDING_CLIPS_SUCCESS = "GET_TRENDING_CLIPS_SUCCESS";
export const GET_TRENDING_CLIPS_FAILURE = "GET_TRENDING_CLIPS_FAILURE";

export const GET_VTUBER_GROUP_CLIPS_REQUEST = "GET_VTUBER_GROUP_CLIPS_REQUEST";
export const GET_VTUBER_GROUP_CLIPS_SUCCESS = "GET_VTUBER_GROUP_CLIPS_SUCCESS";
export const GET_VTUBER_GROUP_CLIPS_FAILURE = "GET_VTUBER_GROUP_CLIPS_FAILURE";

export const GET_MY_VTUBER_LIST_CLIPS_REQUEST =
  "GET_MY_VTUBER_LIST_CLIPS_REQUEST";
export const GET_MY_VTUBER_LIST_CLIPS_SUCCESS =
  "GET_MY_VTUBER_LIST_CLIPS_SUCCESS";
export const GET_MY_VTUBER_LIST_CLIPS_FAILURE =
  "GET_MY_VTUBER_LIST_CLIPS_FAILURE";

export const GET_USERS_CLIPS_REQUEST = "GET_USERS_CLIPS_REQUEST";
export const GET_USERS_CLIPS_SUCCESS = "GET_USERS_CLIPS_SUCCESS";
export const GET_USERS_CLIPS_FAILURE = "GET_USERS_CLIPS_FAILURE";

export const GET_USER_LIKE_CLIPS_REQUEST = "GET_USER_LIKE_CLIPS_REQUEST";
export const GET_USER_LIKE_CLIPS_SUCCESS = "GET_USER_LIKE_CLIPS_SUCCESS";
export const GET_USER_LIKE_CLIPS_FAILURE = "GET_USER_LIKE_CLIPS_FAILURE";

export const GET_RELATED_CLIPS_REQUEST = "GET_RELATED_CLIPS_REQUEST";
export const GET_RELATED_CLIPS_SUCCESS = "GET_RELATED_CLIPS_SUCCESS";
export const GET_RELATED_CLIPS_FAILURE = "GET_RELATED_CLIPS_FAILURE";

export const GET_CLIP_REQUEST = "GET_CLIP_REQUEST";
export const GET_CLIP_SUCCESS = "GET_CLIP_SUCCESS";
export const GET_CLIP_FAILURE = "GET_CLIP_FAILURE";

export const GET_FEED_CLIPS_REQUEST = "GET_FEED_CLIPS_REQUEST";
export const GET_FEED_CLIPS_SUCCESS = "GET_FEED_CLIPS_SUCCESS";
export const GET_FEED_CLIPS_FAILURE = "GET_FEED_CLIPS_FAILURE";

export const PUT_CLIP_REQUEST = "PUT_CLIP_REQUEST";
export const PUT_CLIP_SUCCESS = "PUT_CLIP_SUCCESS";
export const PUT_CLIP_FAILURE = "PUT_CLIP_FAILURE";

export const DELETE_CLIP_REQUEST = "DELETE_CLIP_REQUEST";
export const DELETE_CLIP_SUCCESS = "DELETE_CLIP_SUCCESS";
export const DELETE_CLIP_FAILURE = "DELETE_CLIP_FAILURE";

export const POST_CLIP_LIKE_REQUEST = "POST_CLIP_LIKE_REQUEST";
export const POST_CLIP_LIKE_SUCCESS = "POST_CLIP_LIKE_SUCCESS";
export const POST_CLIP_LIKE_FAILURE = "POST_CLIP_LIKE_FAILURE";

export const POST_CLIP_UNLIKE_REQUEST = "POST_CLIP_UNLIKE_REQUEST";
export const POST_CLIP_UNLIKE_SUCCESS = "POST_CLIP_UNLIKE_SUCCESS";
export const POST_CLIP_UNLIKE_FAILURE = "POST_CLIP_UNLIKE_FAILURE";

export const RESET_CLIP = "RESET_CLIP";
export const RESET_RELATED_CLIPS = "RESET_RELATED_CLIPS";
export const RESET_TRENDING_CLIPS = "RESET_TRENDING_CLIPS";
export const RESET_VTUBER_GROUP_CLIPS = "RESET_VTUBER_GROUP_CLIPS";
export const RESET_USERS_CLIPS = "RESET_USERS_CLIPS";
export const RESET_USER_LIKE_CLIPS = "RESET_USER_LIKE_CLIPS";
export const RESET_FEED_CLIPS = "RESET_FEED_CLIPS";

export const SET_TRENDING_CLIPS_ORDER = "SET_TRENDING_CLIPS_ORDER";
export const SET_VTUBER_GROUP_CLIPS_ORDER = "SET_VTUBER_GROUP_CLIPS_ORDER";
export const SET_USERS_CLIPS_ORDER = "SET_USERS_CLIPS_ORDER";
export const SET_USER_LIKE_CLIPS_ORDER = "SET_USER_LIKE_CLIPS_ORDER";
export const SET_CLIP_STATE = "SET_CLIP_STATE";

export const SET_MYLIST_FILTER_ENABLED = "SET_MYLIST_FILTER_ENABLED";

export const set_mylist_filter_enabled = (enabled) => (dispatch) => {
  dispatch({ type: SET_MYLIST_FILTER_ENABLED, enabled });
};

export const reset_trending_clips = () => (dispatch) => {
  dispatch({ type: RESET_TRENDING_CLIPS });
};
export const reset_feed_clips = () => (dispatch) => {
  dispatch({ type: RESET_FEED_CLIPS });
};

export const reset_vtuber_group_clips = () => (dispatch) => {
  dispatch({ type: RESET_VTUBER_GROUP_CLIPS });
};

export const set_users_clips_order = (order) => (dispatch) => {
  dispatch({ type: SET_USERS_CLIPS_ORDER, order });
};

export const set_user_like_clips_order = (order) => (dispatch) => {
  dispatch({ type: SET_USER_LIKE_CLIPS_ORDER, order });
};

export const set_trending_clips_order = (order) => (dispatch) => {
  dispatch({ type: SET_TRENDING_CLIPS_ORDER, order });
};

export const set_vtuber_group_clips_order = (order) => (dispatch) => {
  dispatch({ type: SET_VTUBER_GROUP_CLIPS_ORDER, order });
};

export const set_clip_state = (state) => (dispatch) => {
  dispatch({ type: SET_CLIP_STATE, state });
};

export const post_clip = ({
  title,
  youtube_video_id,
  start,
  end,
  description,
  vtuber_id_list,
}) => async (dispatch) => {
  dispatch({ type: POST_CLIP_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.post(`${config.backend_api_url}/forum/clips`, {
      clip: {
        title,
        youtube_video_id,
        start,
        end,
        description,
        vtuber_id_list,
      },
    });
    dispatch({ type: POST_CLIP_SUCCESS, res });
  } catch (e) {
    dispatch({ type: POST_CLIP_FAILURE });
  }
};

export const delete_clip = (id) => async (dispatch) => {
  dispatch({ type: DELETE_CLIP_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.delete(
      `${config.backend_api_url}/forum/clips/${id}`
    );
    return dispatch({ type: DELETE_CLIP_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: DELETE_CLIP_FAILURE });
  }
};

export const get_clip = (id) => async (dispatch) => {
  dispatch({ type: GET_CLIP_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(`${config.backend_api_url}/forum/clips/${id}`);
    return dispatch({ type: GET_CLIP_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: GET_CLIP_FAILURE });
  }
};

export const put_clip = ({
  clip_id,
  title,
  description,
  vtuber_id_list = [],
  start,
  end,
}) => async (dispatch) => {
  dispatch({ type: PUT_CLIP_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.put(
      `${config.backend_api_url}/forum/clips/${clip_id}`,
      {
        clip: {
          title,
          description,
          vtuber_id_list,
        },
        clip_fragments: [
          {
            start,
            end,
          },
        ],
      }
    );
    dispatch({ type: PUT_CLIP_SUCCESS, res });
  } catch (e) {
    dispatch({ type: PUT_CLIP_FAILURE });
  }
};

export const increment_loop_count = (id) => async (dispatch) => {
  dispatch({ type: INCREMENT_LOOP_COUNT_REQUEST });

  try {
    const res = await axios.put(
      `${config.backend_api_url}/forum/clips/${id}/loop_count`
    );
    dispatch({ type: INCREMENT_LOOP_COUNT_SUCCESS, res });
  } catch (e) {
    dispatch({ type: INCREMENT_LOOP_COUNT_FAILURE });
  }
};

export const get_related_clips = (id) => (page) => async (dispatch) => {
  dispatch({ type: GET_RELATED_CLIPS_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/forum/clips/${id}/related?page=${page}`
    );
    return dispatch({ type: GET_RELATED_CLIPS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_RELATED_CLIPS_FAILURE });
  }
};

export const get_feed_clips = (page) => async (dispatch) => {
  dispatch({ type: GET_FEED_CLIPS_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/users/clips/feed?page=${page}`
    );
    return dispatch({ type: GET_FEED_CLIPS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_FEED_CLIPS_FAILURE });
  }
};

export const get_trending_clips = (page) => async (dispatch, getState) => {
  dispatch({ type: GET_TRENDING_CLIPS_REQUEST });
  setAuthKeys();

  const { order, filter } = getState().clip.trending;

  try {
    const res = await axios.get(
      `${config.backend_api_url}/forum/clips/trending?page=${page}&order=${order}&filter=${filter}`
    );
    return dispatch({ type: GET_TRENDING_CLIPS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_TRENDING_CLIPS_FAILURE });
  }
};

export const get_vtuber_group_clips = (id) => (page) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_VTUBER_GROUP_CLIPS_REQUEST });
  setAuthKeys();

  const { order } = getState().clip.vtuber_group;

  try {
    const res = await axios.get(
      `${config.backend_api_url}/vtuber_groups/${id}/clips?page=${page}&order=${order}`
    );
    return dispatch({ type: GET_VTUBER_GROUP_CLIPS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_VTUBER_GROUP_CLIPS_FAILURE });
  }
};

export const get_my_vtuber_list_clips = (page) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_MY_VTUBER_LIST_CLIPS_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/my_vtuber_lists/clips/new?page=${page}`
    );
    return dispatch({ type: GET_MY_VTUBER_LIST_CLIPS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_MY_VTUBER_LIST_CLIPS_FAILURE });
  }
};

export const get_users_clips = (id) => (page) => async (dispatch, getState) => {
  dispatch({ type: GET_USERS_CLIPS_REQUEST });
  setAuthKeys();

  const { order } = getState().clip.users;

  try {
    const res = await axios.get(
      `${config.backend_api_url}/forum/clips/users/${id}?page=${page}&order=${order}`
    );
    return dispatch({ type: GET_USERS_CLIPS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_USERS_CLIPS_FAILURE });
  }
};

export const get_user_like_clips = (id) => (page) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_USER_LIKE_CLIPS_REQUEST });
  setAuthKeys();

  const { order } = getState().clip.user_like;

  try {
    const res = await axios.get(
      `${config.backend_api_url}/forum/clips/users/${id}/like?page=${page}&order=${order}`
    );
    return dispatch({ type: GET_USER_LIKE_CLIPS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_USER_LIKE_CLIPS_FAILURE });
  }
};

export const post_like = (id) => async (dispatch) => {
  dispatch({ type: POST_CLIP_LIKE_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.post(
      `${config.backend_api_url}/forum/clips/${id}/like`
    );
    return dispatch({ type: POST_CLIP_LIKE_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: POST_CLIP_LIKE_FAILURE });
  }
};

export const post_unlike = (id) => async (dispatch) => {
  dispatch({ type: POST_CLIP_UNLIKE_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.post(
      `${config.backend_api_url}/forum/clips/${id}/unlike`
    );
    return dispatch({ type: POST_CLIP_UNLIKE_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: POST_CLIP_UNLIKE_FAILURE });
  }
};

export const reset_clip = () => (dispatch) => {
  dispatch({ type: RESET_CLIP });
};

export const reset_users_clips = () => (dispatch) => {
  dispatch({ type: RESET_USERS_CLIPS });
};

export const reset_user_like_clips = () => (dispatch) => {
  dispatch({ type: RESET_USER_LIKE_CLIPS });
};

export const reset_related_clips = () => (dispatch) => {
  dispatch({ type: RESET_RELATED_CLIPS });
};
