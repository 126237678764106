import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from "@material-ui/core/Button";
import { styled as materialStyled } from "@material-ui/core/styles";
import {
  OpenInNewOutlined,
  Home,
  HomeOutlined,
  Search,
  SearchOutlined,
  Whatshot,
  WhatshotOutlined,
  Subscriptions,
  SubscriptionsOutlined,
} from "@material-ui/icons";
import Link from "../atoms/Link";
import "./MuiDrawer.css";

const StyledListItemIcon = styled(ListItemIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledListItem = styled(ListItem)`
  padding-left: 0.8rem !important;
  padding-left: 0.8rem !important;

  ${(props) =>
    props.select ? `background: ${props.theme.colors.gray500};` : ""}

  &:hover {
    background: ${(props) => props.theme.colors.gray600};
  }
`;

const StyledLink = styled(Link)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledA = styled.a`
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  font-size: 1rem;
`;

const OfficialTwitter = styled.span`
  color: ${(props) => props.theme.colors.primary};
  letter-spacing: 0.05rem;
  font-size: 0.95rem;
`;

const StyledListItemText = materialStyled(ListItemText)({
  "& span": {
    fontSize: "0.9rem",
    color: "rgb(70, 70, 70)",
  },
  padding: "0.15rem 0",
});

export default (props) => {
  const isSignedIn = useSelector(
    (state) => state.reduxTokenAuth.currentUser.isSignedIn
  );
  const left_drawer = useSelector((state) => state.user.badge.left_drawer);

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      open={props.open}
      onClose={props.onClose}
      className="LeftDrawer"
    >
      <List
        style={{
          width: "14rem",
        }}
      >
        <StyledLink to="/">
          <StyledListItem
            select={props.selectedPlace === "home"}
            onClick={props.selectedPlace === "home" ? scrollToTop : null}
          >
            <StyledListItemIcon>
              {props.selectedPlace === "home" ? (
                <Home color="primary" />
              ) : (
                <HomeOutlined />
              )}
            </StyledListItemIcon>
            <StyledListItemText selected>ホーム</StyledListItemText>
          </StyledListItem>
        </StyledLink>
        <StyledLink to="/trending">
          <StyledListItem
            select={props.selectedPlace === "trending"}
            onClick={props.selectedPlace === "trending" ? scrollToTop : null}
          >
            <StyledListItemIcon>
              {props.selectedPlace === "trending" ? (
                <Whatshot color="primary" />
              ) : (
                <WhatshotOutlined />
              )}
            </StyledListItemIcon>
            <StyledListItemText>急上昇</StyledListItemText>
          </StyledListItem>
        </StyledLink>
        <StyledLink to="/feed">
          <StyledListItem
            select={props.selectedPlace === "feed"}
            onClick={props.selectedPlace === "feed" ? scrollToTop : null}
          >
            <StyledListItemIcon>
              {props.selectedPlace === "feed" ? (
                <Subscriptions color="primary" />
              ) : (
                <SubscriptionsOutlined />
              )}
            </StyledListItemIcon>

            <Badge
              color="primary"
              variant="dot"
              invisible={left_drawer !== "unread"}
            >
              <StyledListItemText>マイフィード</StyledListItemText>
            </Badge>
          </StyledListItem>
        </StyledLink>
        <StyledLink to="/search">
          <StyledListItem
            select={props.selectedPlace === "search"}
            onClick={props.selectedPlace === "search" ? scrollToTop : null}
          >
            <StyledListItemIcon>
              {props.selectedPlace === "search" ? (
                <Search color="primary" />
              ) : (
                <SearchOutlined />
              )}
            </StyledListItemIcon>
            <StyledListItemText>検索</StyledListItemText>
          </StyledListItem>
        </StyledLink>

        {!isSignedIn && (
          <>
            <Divider style={{ margin: "8px 0" }} />
            <StyledListItem>
              <ListItemText>
                <List>
                  <StyledListItem>
                    <ListItemText>
                      <Link to="/login">
                        <Button variant="outlined" color="primary">
                          ログイン
                        </Button>
                      </Link>
                    </ListItemText>
                  </StyledListItem>
                  <StyledListItem>
                    <ListItemText>
                      <Link to="/registration">
                        <Button color="primary">アカウント作成</Button>
                      </Link>
                    </ListItemText>
                  </StyledListItem>
                </List>
              </ListItemText>
            </StyledListItem>
          </>
        )}

        <Divider style={{ margin: "8px 0" }} />
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/VTubersOfficial"
        >
          <StyledListItem>
            <StyledListItemIcon>
              <OpenInNewOutlined
                style={{ width: 22, height: 22 }}
                color="primary"
              />
            </StyledListItemIcon>
            <ListItemText>
              <OfficialTwitter>公式Twitter</OfficialTwitter>
            </ListItemText>
          </StyledListItem>
        </StyledA>
      </List>
    </Drawer>
  );
};
