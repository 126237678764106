import { combineReducers } from "redux";
import { reduxTokenAuthReducer } from "redux-token-auth";
import vtuber from "./vtuber";
import vtuber_group from "./vtuber_group";
import video from "./video";
import user from "./user";
import search from "./search";
import tag from "./tag";
import tab from "./tab";
import introduction from "./introduction";
import vtuber_registration from "./vtuber_registration";
import report from "./report";
import clip from "./clip";

const reducers = {
  reduxTokenAuth: reduxTokenAuthReducer,
  vtuber,
  vtuber_group,
  video,
  search,
  user,
  tag,
  tab,
  report,
  vtuber_registration,
  introduction,
  clip,
};

const app = combineReducers(reducers);

export default app;
