import {
  GET_TRENDING_INTRODUCTIONS_REQUEST,
  GET_TRENDING_INTRODUCTIONS_SUCCESS,
  GET_TRENDING_INTRODUCTIONS_FAILURE,
  GET_USER_INTRODUCTIONS_REQUEST,
  GET_USER_INTRODUCTIONS_SUCCESS,
  GET_USER_INTRODUCTIONS_FAILURE,
  GET_VTUBER_INTRODUCTIONS_REQUEST,
  GET_VTUBER_INTRODUCTIONS_SUCCESS,
  GET_VTUBER_INTRODUCTIONS_FAILURE,
  GET_INTRODUCTION_REQUEST,
  GET_INTRODUCTION_SUCCESS,
  GET_INTRODUCTION_FAILURE,
  GET_INTRODUCTION_VTUBER_DIRECT_REQUEST,
  GET_INTRODUCTION_VTUBER_DIRECT_SUCCESS,
  GET_INTRODUCTION_VTUBER_DIRECT_FAILURE,
  DELETE_INTRODUCTION_REQUEST,
  DELETE_INTRODUCTION_SUCCESS,
  DELETE_INTRODUCTION_FAILURE,
  POST_INTRODUCTION_REQUEST,
  POST_INTRODUCTION_SUCCESS,
  POST_INTRODUCTION_FAILURE,
  PUT_INTRODUCTION_REQUEST,
  PUT_INTRODUCTION_SUCCESS,
  PUT_INTRODUCTION_FAILURE,
  SET_INTRODUCTION_STATE,
  SET_INTRODUCTION_DELETE_STATE,
  POST_LIKE_REQUEST,
  POST_LIKE_SUCCESS,
  POST_LIKE_FAILURE,
  POST_UNLIKE_REQUEST,
  POST_UNLIKE_SUCCESS,
  POST_UNLIKE_FAILURE,
  SET_TRENDING_ORDER,
  RESET_USER_INTRODUCTIONS,
  RESET_VTUBER_INTRODUCTIONS,
  RESET_TRENDING_INTRODUCTIONS,
  RESET_INTRODUCTION,
} from "../actions/introduction";

const initialState = {
  favorite_point_description_0: "",
  favorite_point_description_1: "",
  favorite_point_description_2: "",
  youtube_video_description_0: "",
  youtube_video_description_1: "",
  youtube_video_description_2: "",
  youtube_video_id_0: "",
  youtube_video_id_1: "",
  youtube_video_id_2: "",
  introduction_likes_count: 0,
  vtuber: undefined,
  owner: undefined,
  trending: {
    order: "created_at",
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  user: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  vtuber_introductions: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  new: {
    state: "sleep",
    query_vtuber: undefined,
  },
  delete: {
    state: "sleep",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_LIKE_REQUEST:
      return { ...state };
    case POST_LIKE_SUCCESS:
      return { ...state };
    case POST_LIKE_FAILURE:
      return { ...state };
    case POST_UNLIKE_REQUEST:
      return { ...state };
    case POST_UNLIKE_SUCCESS:
      return { ...state };
    case POST_UNLIKE_FAILURE:
      return { ...state };
    case GET_TRENDING_INTRODUCTIONS_REQUEST:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "request",
        },
      };
    case GET_TRENDING_INTRODUCTIONS_SUCCESS:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "success",
          items: [...state.trending.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_TRENDING_INTRODUCTIONS_FAILURE:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "failure",
          has_more: false,
        },
      };
    case GET_USER_INTRODUCTIONS_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          state: "request",
        },
      };
    case GET_USER_INTRODUCTIONS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          state: "success",
          items: [...state.user.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_USER_INTRODUCTIONS_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          state: "failure",
          has_more: false,
        },
      };
    case GET_VTUBER_INTRODUCTIONS_REQUEST:
      return {
        ...state,
        vtuber_introductions: {
          ...state.vtuber_introductions,
          state: "request",
        },
      };
    case GET_VTUBER_INTRODUCTIONS_SUCCESS:
      if (state.vtuber_introductions.state !== "request") {
        return {
          ...state,
        };
      }

      return {
        ...state,
        vtuber_introductions: {
          ...state.vtuber_introductions,
          state: "success",
          items: [...state.vtuber_introductions.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_VTUBER_INTRODUCTIONS_FAILURE:
      if (state.vtuber_introductions.state !== "request") {
        return {
          ...state,
        };
      }

      return {
        ...state,
        vtuber_introductions: {
          ...state.vtuber_introductions,
          state: "failure",
          has_more: false,
        },
      };
    case GET_INTRODUCTION_REQUEST:
      return {
        ...state,
        state: "request",
      };
    case GET_INTRODUCTION_SUCCESS:
      return {
        ...state,
        state: "success",
        id: action.res.data.id,
        favorite_point_description_0:
          action.res.data.favorite_point_description_0,
        favorite_point_description_1:
          action.res.data.favorite_point_description_1,
        favorite_point_description_2:
          action.res.data.favorite_point_description_2,
        youtube_video_description_0:
          action.res.data.youtube_video_description_0,
        youtube_video_description_1:
          action.res.data.youtube_video_description_1,
        youtube_video_description_2:
          action.res.data.youtube_video_description_2,
        youtube_video_id_0: action.res.data.youtube_video_id_0,
        youtube_video_id_1: action.res.data.youtube_video_id_1,
        youtube_video_id_2: action.res.data.youtube_video_id_2,
        vtuber: action.res.data.vtuber,
        owner: action.res.data.owner,
        is_liked: action.res.data.is_liked,
        introduction_likes_count: action.res.data.introduction_likes_count,
      };
    case GET_INTRODUCTION_FAILURE:
      return {
        ...state,
        state: "failure",
      };
    case GET_INTRODUCTION_VTUBER_DIRECT_REQUEST:
      return {
        ...state,
        new: {
          ...state.new,
        },
      };
    case GET_INTRODUCTION_VTUBER_DIRECT_SUCCESS:
      return {
        ...state,
        new: {
          ...state.new,
          query_vtuber: action.res.data,
        },
      };
    case GET_INTRODUCTION_VTUBER_DIRECT_FAILURE:
      return {
        ...state,
        new: {
          ...state.new,
        },
      };
    case POST_INTRODUCTION_REQUEST:
      return { ...state, new: { ...state.new, state: "request" } };
    case POST_INTRODUCTION_SUCCESS:
      return {
        ...state,
        new: { ...state.new, introduction: action.res.data, state: "success" },
      };

    case POST_INTRODUCTION_FAILURE:
      return { ...state, new: { ...state.new, state: "failure" } };
    case PUT_INTRODUCTION_FAILURE:
      return { ...state, put: { ...state.new, state: "failure" } };
    case PUT_INTRODUCTION_REQUEST:
      return { ...state, put: { ...state.new, state: "request" } };
    case PUT_INTRODUCTION_SUCCESS:
      return {
        ...state,
        favorite_point_description_0:
          action.res.data.favorite_point_description_0,
        favorite_point_description_1:
          action.res.data.favorite_point_description_1,
        favorite_point_description_2:
          action.res.data.favorite_point_description_2,
        youtube_video_description_0:
          action.res.data.youtube_video_description_0,
        youtube_video_description_1:
          action.res.data.youtube_video_description_1,
        youtube_video_description_2:
          action.res.data.youtube_video_description_2,
        youtube_video_id_0: action.res.data.youtube_video_id_0,
        youtube_video_id_1: action.res.data.youtube_video_id_1,
        youtube_video_id_2: action.res.data.youtube_video_id_2,
        put: { ...state.new, introduction: action.res.data, state: "success" },
      };
    case DELETE_INTRODUCTION_REQUEST:
      return { ...state, delete: { ...state.delete, state: "request" } };
    case DELETE_INTRODUCTION_SUCCESS:
      return {
        ...state,
        delete: { ...state.delete, state: "success" },
      };

    case DELETE_INTRODUCTION_FAILURE:
      return { ...state, delete: { ...state.delete, state: "failure" } };
    case SET_INTRODUCTION_STATE:
      return { ...state, new: { ...state.new, state: action.state } };
    case SET_INTRODUCTION_DELETE_STATE:
      return { ...state, delete: { ...state.delete, state: action.state } };
    case SET_TRENDING_ORDER:
      return {
        ...state,
        trending: {
          ...state.trending,
          order: action.order,
        },
      };
    case RESET_USER_INTRODUCTIONS:
      return {
        ...state,
        user: {
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case RESET_VTUBER_INTRODUCTIONS:
      return {
        ...state,
        vtuber_introductions: {
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case RESET_TRENDING_INTRODUCTIONS:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case RESET_INTRODUCTION:
      return {
        ...state,
        favorite_point_description_0: "",
        favorite_point_description_1: "",
        favorite_point_description_2: "",
        youtube_video_description_0: "",
        youtube_video_description_1: "",
        youtube_video_description_2: "",
        youtube_video_id_0: "",
        youtube_video_id_1: "",
        youtube_video_id_2: "",
        introduction_likes_count: 0,
        vtuber: undefined,
        owner: undefined,
      };
    default:
      return { ...state };
  }
};
