import {
  GET_TRENDING_VTUBERS_REQUEST,
  GET_TRENDING_VTUBERS_SUCCESS,
  GET_TRENDING_VTUBERS_FAILURE,
  GET_NEW_VTUBERS_REQUEST,
  GET_NEW_VTUBERS_SUCCESS,
  GET_NEW_VTUBERS_FAILURE,
  GET_SUBSCRIBER_NUM_LOG_REQUEST,
  GET_SUBSCRIBER_NUM_LOG_SUCCESS,
  GET_SUBSCRIBER_NUM_LOG_FAILURE,
  GET_VTUBERS_CLIPS_REQUEST,
  GET_VTUBERS_CLIPS_SUCCESS,
  GET_VTUBERS_CLIPS_FAILURE,
  GET_NEXT_BREAK_VTUBERS_REQUEST,
  GET_NEXT_BREAK_VTUBERS_SUCCESS,
  GET_NEXT_BREAK_VTUBERS_FAILURE,
  GET_RECOMMENDED_VTUBERS_REQUEST,
  GET_RECOMMENDED_VTUBERS_SUCCESS,
  GET_RECOMMENDED_VTUBERS_FAILURE,
  GET_RELATED_VTUBERS_REQUEST,
  GET_RELATED_VTUBERS_SUCCESS,
  GET_RELATED_VTUBERS_FAILURE,
  GET_TRENDING_VTUBERS_VTUBERS_REQUEST,
  GET_TRENDING_VTUBERS_VTUBERS_SUCCESS,
  GET_TRENDING_VTUBERS_VTUBERS_FAILURE,
  GET_VTUBER_REQUEST,
  GET_VTUBER_SUCCESS,
  GET_VTUBER_FAILURE,
  GET_VTUBER_FOR_TWEET_REQUEST,
  GET_VTUBER_FOR_TWEET_SUCCESS,
  GET_VTUBER_FOR_TWEET_FAILURE,
  GET_VTUBER_VIDEOS_REQUEST,
  GET_VTUBER_VIDEOS_SUCCESS,
  GET_VTUBER_VIDEOS_FAILURE,
  RESET_VTUBER_VIDEOS,
  RESET_VTUBER_NAME,
  RESET_RELATED_VTUBERS,
  RESET_NEXT_BREAK_VTUBERS,
  RESET_VTUBERS_CLIPS,
  SET_VTUBERS_CLIPS_ORDER,
  SET_NEXT_BREAK_LIMIT,
  RESET_TRENDING_VTUBERS,
  SET_TRENDING_VTUBERS_ORDER,
} from "../actions/vtuber";

const initialState = {
  state: "sleep",
  title: "",
  tags: [],

  trending: {
    order: "weekly",
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  new: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  next_break: {
    limit: 5000,
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  recommended: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  trending_sole: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  related: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  vtubers_trending: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  videos: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  clips: {
    order: "created_at",
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  tweet: {
    name: "",
    clips_count: 0,
    introductions_count: 0,
    likes_count: 0,
    top_k_clips: [],
  },
  subscriber_num_log: {
    state: "sleep",
    items: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRENDING_VTUBERS_REQUEST:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "request",
        },
      };
    case GET_TRENDING_VTUBERS_SUCCESS:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "success",
          items: [...state.trending.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_TRENDING_VTUBERS_FAILURE:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "failure",
          has_more: false,
        },
      };
    case GET_NEW_VTUBERS_REQUEST:
      return {
        ...state,
        new: {
          ...state.new,
          state: "request",
        },
      };
    case GET_NEW_VTUBERS_SUCCESS:
      return {
        ...state,
        new: {
          ...state.new,
          state: "success",
          items: [...state.new.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_NEW_VTUBERS_FAILURE:
      return {
        ...state,
        new: {
          ...state.new,
          state: "failure",
          has_more: false,
        },
      };

    case GET_SUBSCRIBER_NUM_LOG_REQUEST:
      return {
        ...state,
        subscriber_num_log: {
          ...state.subscriber_num_log,
          state: "request",
        },
      };

    case GET_SUBSCRIBER_NUM_LOG_SUCCESS:
      return {
        ...state,
        subscriber_num_log: {
          ...state.subscriber_num_log,
          items: action.res.data,
          state: "success",
        },
      };

    case GET_SUBSCRIBER_NUM_LOG_FAILURE:
      return {
        ...state,
        subscriber_num_log: {
          ...state.subscriber_num_log,
          state: "failure",
        },
      };

    case GET_NEXT_BREAK_VTUBERS_REQUEST:
      return {
        ...state,
        next_break: {
          ...state.next_break,
          state: "request",
        },
      };
    case GET_NEXT_BREAK_VTUBERS_SUCCESS:
      return {
        ...state,
        next_break: {
          ...state.next_break,
          state: "success",
          items: [...state.next_break.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_NEXT_BREAK_VTUBERS_FAILURE:
      return {
        ...state,
        next_break: {
          ...state.next_break,
          state: "failure",
          has_more: false,
        },
      };
    case GET_RECOMMENDED_VTUBERS_REQUEST:
      return {
        ...state,
        recommended: {
          ...state.recommended,
          state: "request",
        },
      };
    case GET_RECOMMENDED_VTUBERS_SUCCESS:
      return {
        ...state,
        recommended: {
          ...state.recommended,
          state: "success",
          items: [...state.recommended.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_RECOMMENDED_VTUBERS_FAILURE:
      return {
        ...state,
        recommended: {
          ...state.recommended,
          state: "failure",
          has_more: false,
        },
      };
    case GET_RELATED_VTUBERS_REQUEST:
      return {
        ...state,
        related: {
          ...state.related,
          state: "request",
        },
      };
    case GET_RELATED_VTUBERS_SUCCESS:
      return {
        ...state,
        related: {
          ...state.related,
          state: "success",
          items: [...state.related.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_RELATED_VTUBERS_FAILURE:
      return {
        ...state,
        related: {
          ...state.related,
          state: "failure",
          has_more: false,
        },
      };
    case GET_TRENDING_VTUBERS_VTUBERS_REQUEST:
      return {
        ...state,
        vtubers_trending: {
          ...state.vtubers_trending,
          state: "request",
        },
      };
    case GET_TRENDING_VTUBERS_VTUBERS_SUCCESS:
      return {
        ...state,
        vtubers_trending: {
          ...state.vtubers_trending,
          state: "success",
          items: [...state.vtubers_trending.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_TRENDING_VTUBERS_VTUBERS_FAILURE:
      return {
        ...state,
        vtubers_trending: {
          ...state.vtubers_trending,
          state: "failure",
          has_more: false,
        },
      };
    case GET_VTUBER_REQUEST:
      return {
        ...state,
        state: "request",
      };
    case GET_VTUBER_SUCCESS:
      return {
        ...state,
        state: "success",
        id: action.res.data.id,
        name: action.res.data.name,
        image: action.res.data.image,
        description: action.res.data.description,
        phrase: action.res.data.phrase,
        youtube_channels: [...action.res.data.youtube_channels],
        twitter_id: action.res.data.twitter_id,
        groups: action.res.data.groups,
        is_added: action.res.data.is_added,
        tags: action.res.data.tags,
        my_vtuber_lists_count: action.res.data.my_vtuber_lists_count,
        url: action.res.data.url,
        link_title: action.res.data.link_title,
      };
    case GET_VTUBER_FAILURE:
      return {
        ...state,
        state: "failure",
      };
    case GET_VTUBER_FOR_TWEET_REQUEST:
      return {
        ...state,
        tweet: {
          state: "request",
        },
      };
    case GET_VTUBER_FOR_TWEET_SUCCESS:
      return {
        ...state,
        tweet: {
          state: "success",
          ...action.res.data,
        },
      };
    case GET_VTUBER_FOR_TWEET_FAILURE:
      return {
        ...state,
        tweet: {
          state: "failure",
        },
      };
    case GET_VTUBER_VIDEOS_REQUEST:
      return {
        ...state,
        videos: {
          ...state.videos,
          state: "request",
        },
      };
    case GET_VTUBER_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: {
          ...state.videos,
          state: "success",
          items:
            action.page < state.page
              ? [...action.res.data]
              : [...state.videos.items, ...action.res.data.items],
          has_more: action.res.data.items.length > 0,
          page: action.page,
        },
      };
    case GET_VTUBER_VIDEOS_FAILURE:
      return {
        ...state,
        videos: {
          ...state.videos,
          state: "failure",
          has_more: false,
        },
      };
    case GET_VTUBERS_CLIPS_REQUEST:
      return {
        ...state,
        clips: {
          ...state.clips,
          state: "request",
        },
      };
    case GET_VTUBERS_CLIPS_SUCCESS:
      return {
        ...state,
        clips: {
          ...state.clips,
          state: "success",
          items:
            action.page < state.page
              ? [...action.res.data.items]
              : [...state.clips.items, ...action.res.data.items],
          has_more: action.res.data.items.length > 0,
          page: action.page,
        },
      };
    case GET_VTUBERS_CLIPS_FAILURE:
      return {
        ...state,
        clips: {
          ...state.clips,
          state: "failure",
          has_more: false,
        },
      };
    case RESET_VTUBER_VIDEOS:
      return {
        ...state,
        videos: {
          ...state.videos,
          has_more: true,
          state: "sleep",
          page: 0,
          items: [],
        },
      };
    case RESET_VTUBERS_CLIPS:
      return {
        ...state,
        clips: {
          ...state.clips,
          has_more: true,
          state: state.clips.state === "request" ? "request" : "sleep",
          page: 0,
          items: [],
        },
      };
    case RESET_VTUBER_NAME:
      return {
        ...state,
        name: undefined,
      };
    case RESET_RELATED_VTUBERS:
      return {
        ...state,
        related: {
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case RESET_NEXT_BREAK_VTUBERS:
      return {
        ...state,
        next_break: {
          ...state.next_break,
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case SET_VTUBERS_CLIPS_ORDER:
      return {
        ...state,
        clips: {
          ...state.clips,
          order: action.order,
        },
      };
    case SET_NEXT_BREAK_LIMIT:
      return {
        ...state,
        next_break: {
          ...state.next_break,
          limit: action.limit,
        },
      };
    case RESET_TRENDING_VTUBERS:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case SET_TRENDING_VTUBERS_ORDER:
      return {
        ...state,
        trending: {
          ...state.trending,
          order: action.order,
        },
      };

    default:
      return { ...state };
  }
};
