import React from "react";
import LogoSrc from "../../assets/images/vtubers.png";

const Logo = () => {
  return (
    <img
      src={LogoSrc}
      alt="logo"
      style={{ height: "22px", padding: "0 8px" }}
    />
  );
};

export default Logo;
