import styled from "styled-components";

export default styled.div`
  @media (max-width: 1124px) {
    display: none;
  }
`;

export const Other = styled.div`
  @media (min-width: 1125px) {
    display: none;
  }
`;
