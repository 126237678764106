import axios from "axios";
import config from "../config";
import { setAuthKeys } from "../auth";

export const SET_BADGE_STATE = "SET_BADGE_STATE";

export const FOLLOW_REQUEST = "FOLLOW_REQUEST";
export const FOLLOW_SUCCESS = "FOLLOW_SUCCESS";
export const FOLLOW_FAILURE = "FOLLOW_FAILURE";

export const UNFOLLOW_REQUEST = "UNFOLLOW_REQUEST";
export const UNFOLLOW_SUCCESS = "UNFOLLOW_SUCCESS";
export const UNFOLLOW_FAILURE = "UNFOLLOW_FAILURE";

export const CHECK_NOTIFICATIONS_REQUEST = "CHECK_NOTIFICATIONS_REQUEST";
export const CHECK_NOTIFICATIONS_SUCCESS = "CHECK_NOTIFICATIONS_SUCCESS";
export const CHECK_NOTIFICATIONS_FAILURE = "CHECK_NOTIFICATIONS_FAILURE";

export const GET_TRENDING_USERS_REQUEST = "GET_TRENDING_USERS_REQUEST";
export const GET_TRENDING_USERS_SUCCESS = "GET_TRENDING_USERS_SUCCESS";
export const GET_TRENDING_USERS_FAILURE = "GET_TRENDING_USERS_FAILURE";

export const GET_FOLLOW_USERS_REQUEST = "GET_FOLLOW_USERS_REQUEST";
export const GET_FOLLOW_USERS_SUCCESS = "GET_FOLLOW_USERS_SUCCESS";
export const GET_FOLLOW_USERS_FAILURE = "GET_FOLLOW_USERS_FAILURE";

export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const EDIT_USER_ATTRIBUTES_REQUEST = "EDIT_USER_ATTRIBUTES_REQUEST";
export const EDIT_USER_ATTRIBUTES_SUCCESS = "EDIT_USER_ATTRIBUTES_SUCCESS";
export const EDIT_USER_ATTRIBUTES_FAILURE = "EDIT_USER_ATTRIBUTES_FAILURE";

export const GET_MY_VTUBER_LIST_REQUEST = "GET_MY_VTUBER_LIST_REQUEST";
export const GET_MY_VTUBER_LIST_SUCCESS = "GET_MY_VTUBER_LIST_SUCCESS";
export const GET_MY_VTUBER_LIST_FAILURE = "GET_MY_VTUBER_LIST_FAILURE";

export const RESET_MY_VTUBER_LIST = "RESET_MY_VTUBER_LIST";

export const ADD_MY_VTUBER_LIST_REQUEST = "ADD_MY_VTUBER_LIST_REQUEST";
export const ADD_MY_VTUBER_LIST_SUCCESS = "ADD_MY_VTUBER_LIST_SUCCESS";
export const ADD_MY_VTUBER_LIST_FAILURE = "ADD_MY_VTUBER_LIST_FAILURE";

export const REMOVE_MY_VTUBER_LIST_REQUEST = "REMOVE_MY_VTUBER_LIST_REQUEST";
export const REMOVE_MY_VTUBER_LIST_SUCCESS = "REMOVE_MY_VTUBER_LIST_SUCCESS";
export const REMOVE_MY_VTUBER_LIST_FAILURE = "REMOVE_MY_VTUBER_LIST_FAILURE";

export const UPLOAD_USER_AVATAR_REQUEST = "UPLOAD_USER_AVATAR_REQUEST";
export const UPLOAD_USER_AVATAR_SUCCESS = "UPLOAD_USER_AVATAR_SUCCESS";
export const UPLOAD_USER_AVATAR_FAILURE = "UPLOAD_USER_AVATAR_FAILURE";

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

export const PASSWORD_CHANGE_REQUEST = "PASSWORD_CHANGE_REQUEST";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_FAILURE = "PASSWORD_CHANGE_FAILURE";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

export const READ_NOTIFICAITONS_REQUEST = "READ_NOTIFICAITONS_REQUEST";
export const READ_NOTIFICAITONS_SUCCESS = "READ_NOTIFICAITONS_SUCCESS";
export const READ_NOTIFICAITONS_FAILURE = "READ_NOTIFICAITONS_FAILURE";

export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";

export const RESET_TRENDING_USERS = "RESET_TRENDING_USERS";

export const SET_TRENDING_USERS_OPTION = "SET_TRENDING_USERS_OPTION";

export const set_badge_state = (state) => (dispatch) => {
  dispatch({ type: SET_BADGE_STATE, state });
};

export const get_follow_users = (page) => async (dispatch) => {
  dispatch({ type: GET_FOLLOW_USERS_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/users/follow?page=${page}`
    );
    return dispatch({ type: GET_FOLLOW_USERS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_FOLLOW_USERS_FAILURE });
  }
};

export const follow = (followee_user_id) => async (dispatch) => {
  dispatch({ type: FOLLOW_REQUEST, followee_user_id });
  setAuthKeys();

  try {
    const res = await axios.post(
      `${config.backend_api_url}/users/${followee_user_id}/follow`
    );
    return dispatch({
      type: FOLLOW_SUCCESS,
      followee_user_id,
      res,
    });
  } catch (err) {
    console.error(err);
    dispatch({ type: FOLLOW_FAILURE, followee_user_id });
  }
};

export const unfollow = (followee_user_id) => async (dispatch) => {
  dispatch({ type: UNFOLLOW_REQUEST, followee_user_id });
  setAuthKeys();

  try {
    const res = await axios.post(
      `${config.backend_api_url}/users/${followee_user_id}/unfollow`
    );
    return dispatch({
      type: UNFOLLOW_SUCCESS,
      followee_user_id,
      res,
    });
  } catch (err) {
    console.error(err);
    dispatch({ type: UNFOLLOW_FAILURE, followee_user_id });
  }
};

export const reset_trending_users = () => (dispatch) => {
  dispatch({ type: RESET_TRENDING_USERS });
};

export const set_trending_users_option = (option) => (dispatch) => {
  dispatch({ type: SET_TRENDING_USERS_OPTION, option });
};

export const reset_notrifications = () => (dispatch) => {
  dispatch({ type: RESET_NOTIFICATIONS });
};

export const read_notifications = (notifications_id_list) => async (
  dispatch,
  getState
) => {
  const state = getState();
  if (state.user.notification.state === "request") {
    return;
  }

  dispatch({ type: READ_NOTIFICAITONS_REQUEST, notifications_id_list });
  setAuthKeys();

  try {
    const res = await axios.patch(`${config.backend_api_url}/notifications`, {
      notifications_id_list,
    });
    return dispatch({
      type: READ_NOTIFICAITONS_SUCCESS,
      notifications_id_list,
      res,
    });
  } catch (err) {
    console.error(err);
    dispatch({ type: READ_NOTIFICAITONS_FAILURE, notifications_id_list });
  }
};

export const get_trending_users = (page) => async (dispatch, getState) => {
  dispatch({ type: GET_TRENDING_USERS_REQUEST });
  setAuthKeys();

  const state = getState();
  const { option } = state.user.trending;

  try {
    const res = await axios.get(
      `${config.backend_api_url}/users/trending?page=${page}&option=${option}`
    );
    return dispatch({ type: GET_TRENDING_USERS_SUCCESS, res, page, option });
  } catch (e) {
    return dispatch({ type: GET_TRENDING_USERS_FAILURE });
  }
};

export const check_notifications = () => async (dispatch, getState) => {
  const state = getState();
  if (state.user.my_account.state === "request") {
    return;
  }

  dispatch({ type: CHECK_NOTIFICATIONS_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/notifications/not_read_count`
    );
    return dispatch({ type: CHECK_NOTIFICATIONS_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: CHECK_NOTIFICATIONS_FAILURE });
  }
};

export const get_notifications = (page) => async (dispatch) => {
  dispatch({ type: GET_NOTIFICATIONS_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/notifications?page=${page}`
    );
    return dispatch({ type: GET_NOTIFICATIONS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_NOTIFICATIONS_FAILURE });
  }
};

export const reset_password = (email) => async (dispatch) => {
  dispatch({ type: PASSWORD_RESET_REQUEST });

  try {
    await axios.post(`${config.backend_api_url}/auth/password`, {
      email,
      redirect_url: `${config.frontend_base_url}/password_change`,
    });
    return dispatch({ type: PASSWORD_RESET_SUCCESS, email });
  } catch (e) {
    return dispatch({ type: PASSWORD_RESET_FAILURE, error: e });
  }
};

export const change_password = (password, password_confirmation) => async (
  dispatch
) => {
  dispatch({ type: PASSWORD_CHANGE_REQUEST });

  try {
    setAuthKeys();
    await axios.put(`${config.backend_api_url}/auth/password`, {
      password,
      password_confirmation,
    });
    return dispatch({ type: PASSWORD_CHANGE_SUCCESS });
  } catch (e) {
    if (e.response) {
      return dispatch({
        type: PASSWORD_CHANGE_FAILURE,
        error: { response: e.response },
      });
    }
  }
};

export const delete_user = () => async (dispatch) => {
  dispatch({ type: USER_DELETE_REQUEST });

  try {
    setAuthKeys();
    await axios.delete(`${config.backend_api_url}/auth/`);
    return dispatch({ type: USER_DELETE_SUCCESS });
  } catch (e) {
    return dispatch({ type: USER_DELETE_FAILURE });
  }
};

export const reset_my_vtuber_list = () => (dispatch, getState) => {
  const state = getState();
  if (state.user.my_vtuber_list.state === "request") {
    return;
  }
  dispatch({ type: RESET_MY_VTUBER_LIST });
};

export const edit_user_attributes = (attributes) => async (dispatch) => {
  dispatch({ type: EDIT_USER_ATTRIBUTES_REQUEST });
  setAuthKeys();

  const { name, description, twitter_id } = attributes;

  try {
    const res = await axios.patch(`${config.backend_api_url}/auth`, {
      name,
      description,
      twitter_id,
    });
    return dispatch({ type: EDIT_USER_ATTRIBUTES_SUCCESS, res });
  } catch (err) {
    return dispatch({ type: EDIT_USER_ATTRIBUTES_FAILURE, err });
  }
};

export const get_user = (id) => async (dispatch) => {
  dispatch({ type: GET_USER_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(`${config.backend_api_url}/users/${id}`);
    return dispatch({ type: GET_USER_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: GET_USER_FAILURE });
  }
};

export const get_my_vtuber_list = (id, is_mine = true) => (page) => async (
  dispatch,
  getState
) => {
  const state = getState();
  if (
    (is_mine &&
      (state.user.my_vtuber_list.state === "request" ||
        page <= state.user.my_vtuber_list.page)) ||
    (!is_mine &&
      (state.user.user.my_vtuber_list.state === "request" ||
        page <= state.user.user.my_vtuber_list.page))
  ) {
    return;
  }
  dispatch({ type: GET_MY_VTUBER_LIST_REQUEST, is_mine });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/my_vtuber_lists/${id}${
        page === undefined ? "" : `?page=${page}`
      }`
    );
    return dispatch({ type: GET_MY_VTUBER_LIST_SUCCESS, res, is_mine, page });
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_MY_VTUBER_LIST_FAILURE, is_mine });
  }
};

export const add_my_vtuber_list = (vtuber_id) => async (dispatch) => {
  dispatch({ type: ADD_MY_VTUBER_LIST_REQUEST, id: vtuber_id });
  setAuthKeys();

  try {
    const res = await axios.post(`${config.backend_api_url}/my_vtuber_lists`, {
      my_vtuber_list: {
        vtuber_id,
      },
    });
    return dispatch({ type: ADD_MY_VTUBER_LIST_SUCCESS, id: vtuber_id, res });
  } catch (err) {
    console.error(err);
    dispatch({ type: ADD_MY_VTUBER_LIST_FAILURE, id: vtuber_id });
  }
};

export const remove_my_vtuber_list = (vtuber_id) => async (dispatch) => {
  dispatch({ type: REMOVE_MY_VTUBER_LIST_REQUEST, id: vtuber_id });
  setAuthKeys();

  try {
    const res = await axios.delete(
      `${config.backend_api_url}/my_vtuber_lists`,
      {
        data: {
          my_vtuber_list: {
            vtuber_id,
          },
        },
      }
    );
    return dispatch({
      type: REMOVE_MY_VTUBER_LIST_SUCCESS,
      id: vtuber_id,
      res,
    });
  } catch (err) {
    console.error(err);
    dispatch({ type: REMOVE_MY_VTUBER_LIST_FAILURE, id: vtuber_id });
  }
};

export const upload_user_avatar = (submit_data) => async (dispatch) => {
  dispatch({ type: UPLOAD_USER_AVATAR_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.post(
      `${config.backend_api_url}/users/current/avatar`,
      submit_data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    return dispatch({ type: UPLOAD_USER_AVATAR_SUCCESS, res });
  } catch (err) {
    console.error(err);
    dispatch({ type: UPLOAD_USER_AVATAR_FAILURE });
  }
};
