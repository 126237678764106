import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import BasicPageTemplate from "./components/organisms/BasicPageTemplate";
import CircularProgress from "@material-ui/core/CircularProgress";
import Wrapper from "./components/atoms/Wrapper";

const CircularWrapper = styled(Wrapper)`
  width: 100%;
  height: 90vh;
`;

export default function LazyFallback() {
  const location = useLocation();
  let selectedPlace = undefined;
  switch (location.pathname) {
    case "/":
      selectedPlace = "home";
      break;
    case "/trending":
      selectedPlace = "trending";
      break;
    case "/feed":
      selectedPlace = "feed";
      break;
    case "/search":
      selectedPlace = "search";
      break;
    default:
      selectedPlace = undefined;
  }
  return (
    <BasicPageTemplate selectedPlace={selectedPlace} leftDrawerOpen>
      <CircularWrapper>
        <CircularProgress />
      </CircularWrapper>
    </BasicPageTemplate>
  );
}
