import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Wrapper from "../atoms/Wrapper";
import RightDrawer from "./RightDrawer";
import LeftDrawer from "./LeftDrawer";
import PcComponent from "../atoms/PcComponent";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Link from "../atoms/Link";
import "./MuiBottomNav.css";
import {
  Home,
  HomeOutlined,
  Search,
  SearchOutlined,
  Whatshot,
  WhatshotOutlined,
  Subscriptions,
  SubscriptionsOutlined,
} from "@material-ui/icons";

const ContentWrapper = styled(Wrapper)`
  width: ${(props) => (props.leftDrawerOpen ? "calc(100vw - 14rem)" : "100vw")};
  margin-left: ${(props) => (props.leftDrawerOpen ? "14rem" : "0")};
  padding-top: 0;
  padding-bottom: 0.2rem;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
  justify-content: flex-start;

  flex-direction: column;
  word-break: break-all;
  background: ${(props) => props.theme.colors.white};

  @media (max-width: 1124px) {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 56px;
  }
`;

const StyledBottomNav = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 50px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 99999999;

  @media (min-width: 1124px) {
    display: none !important;
  }

  @media screen and (max-width: 600px) {
    width: 650px !important;
  }
`;

const StyledBottomNavAction = styled(BottomNavigationAction)`
  padding-bottom: 6px !important;
`;

const StyledLink = styled(Link)`
  line-height: normal;
  letter-spacing: normal;
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: auto !important;
`;

export default (props) => {
  const [right_drawer_open, set_right_drawer_open] = React.useState(false);
  const handle_right_drawer = (state) => (e) => {
    if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return;
    }
    set_right_drawer_open(state);
  };
  const [left_drawer_open, set_left_drawer_open] = React.useState(
    props.leftDrawerOpen
  );
  const handle_left_drawer = (e) => {
    if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return;
    }
    set_left_drawer_open(!left_drawer_open);
  };

  const get_initial_nav_selected = () => {
    if (props.selectedPlace === "home") {
      return 0;
    } else if (props.selectedPlace === "trending") {
      return 1;
    } else if (props.selectedPlace === "feed") {
      return 2;
    } else if (props.selectedPlace === "search") {
      return 3;
    }
    return -1;
  };

  const [nav_selected, set_nav_selected] = React.useState(
    get_initial_nav_selected()
  );

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header
        toggle_left_drawer={handle_left_drawer}
        open_right_drawer={handle_right_drawer(true)}
      />
      <Wrapper>
        <PcComponent>
          <LeftDrawer
            open={left_drawer_open}
            onClose={handle_left_drawer}
            selectedPlace={props.selectedPlace}
          />
        </PcComponent>
        <ContentWrapper leftDrawerOpen={left_drawer_open}>
          {props.children}
        </ContentWrapper>
      </Wrapper>
      <StyledBottomNav
        value={nav_selected}
        onChange={(event, new_value) => {
          set_nav_selected(new_value);
        }}
        showLabels
      >
        <StyledBottomNavAction
          label="ホーム"
          component={StyledLink}
          to="/"
          icon={
            props.selectedPlace === "home" ? (
              <Home onClick={scrollToTop} />
            ) : (
              <HomeOutlined
                style={{
                  width: 21,
                  height: 21,
                }}
              />
            )
          }
        />
        <StyledBottomNavAction
          label="急上昇"
          component={StyledLink}
          to="/trending"
          icon={
            props.selectedPlace === "trending" ? (
              <Whatshot onClick={scrollToTop} />
            ) : (
              <WhatshotOutlined
                style={{
                  width: 21,
                  height: 21,
                }}
              />
            )
          }
        />
        <StyledBottomNavAction
          label="マイフィード"
          component={StyledLink}
          to="/feed"
          icon={
            props.selectedPlace === "feed" ? (
              <Subscriptions onClick={scrollToTop} />
            ) : (
              <SubscriptionsOutlined
                style={{
                  width: 21,
                  height: 21,
                }}
              />
            )
          }
        />
        <StyledBottomNavAction
          label="検索"
          component={StyledLink}
          to="/search"
          icon={
            props.selectedPlace === "search" ? (
              <Search onClick={scrollToTop} />
            ) : (
              <SearchOutlined
                style={{
                  width: 21,
                  height: 21,
                }}
              />
            )
          }
        />
      </StyledBottomNav>

      <RightDrawer
        open={right_drawer_open}
        onClose={handle_right_drawer(false)}
      />
    </>
  );
};
