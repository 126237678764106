import axios from "axios";
import config from "../config";
import { setAuthKeys } from "../auth";

export const SEARCH = "SEARCH";
export const SET_W = "SET_W";

export const GET_SEARCH_RESULTS_REQUEST = "GET_SEARCH_RESULTS_REQUEST";
export const GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS";
export const GET_SEARCH_RESULTS_FAILURE = "GET_SEARCH_RESULTS_FAILURE";

export const search = (search_query, tags, w) => (dispatch, getState) => {
  dispatch({ type: SEARCH, search_query, tags, w: w ? w : "clip" });
};

export const set_w = w => dispatch => {
  dispatch({ type: SET_W, w });
};

export const get_search_results = page => (dispatch, getState) => {
  const state = getState();

  dispatch({ type: GET_SEARCH_RESULTS_REQUEST });

  const w = state.search.w;
  const params = {
    q: state.search.search_query,
    w,
    page
  };
  const valid_params_keys = Object.keys(params).filter(k => params[k]);
  const url = `${config.backend_api_url}/search?${valid_params_keys
    .map(
      (key, idx) =>
        (idx === 0 ? "" : "&") + key + "=" + encodeURIComponent(params[key])
    )
    .join("")}${
    state.search.tags && state.search.tags.length > 0
      ? state.search.tags.map(tag => `&t%5B%5D=${tag}`).join("")
      : ""
  }`;

  setAuthKeys();
  return axios
    .get(url)
    .then(res => dispatch({ type: GET_SEARCH_RESULTS_SUCCESS, res, w, page }))
    .catch(() => dispatch({ type: GET_SEARCH_RESULTS_FAILURE, w }));
};
