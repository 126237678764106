import axios from "axios";
import config from "../config";
import { setAuthKeys } from "../auth";

export const GET_TRENDING_VIDEOS_REQUEST = "GET_TRENDING_VIDEOS_REQUEST";
export const GET_TRENDING_VIDEOS_SUCCESS = "GET_TRENDING_VIDEOS_SUCCESS";
export const GET_TRENDING_VIDEOS_FAILURE = "GET_TRENDING_VIDEOS_FAILURE";

export const GET_NEW_VIDEOS_REQUEST = "GET_NEW_VIDEOS_REQUEST";
export const GET_NEW_VIDEOS_SUCCESS = "GET_NEW_VIDEOS_SUCCESS";
export const GET_NEW_VIDEOS_FAILURE = "GET_NEW_VIDEOS_FAILURE";

export const GET_MY_VTUBER_LIST_VIDEOS_REQUEST =
  "GET_MY_VTUBER_LIST_VIDEOS_REQUEST";
export const GET_MY_VTUBER_LIST_VIDEOS_SUCCESS =
  "GET_MY_VTUBER_LIST_VIDEOS_SUCCESS";
export const GET_MY_VTUBER_LIST_VIDEOS_FAILURE =
  "GET_MY_VTUBER_LIST_VIDEOS_FAILURE";

export const get_trending_videos = page => dispatch => {
  dispatch({ type: GET_TRENDING_VIDEOS_REQUEST });

  return axios
    .get(`${config.backend_api_url}/videos/trending?page=${page}`)
    .then(res => dispatch({ type: GET_TRENDING_VIDEOS_SUCCESS, res, page }))
    .catch(() => dispatch({ type: GET_TRENDING_VIDEOS_FAILURE }));
};

export const get_new_videos = page => dispatch => {
  dispatch({ type: GET_NEW_VIDEOS_REQUEST });

  return axios
    .get(`${config.backend_api_url}/videos/new?page=${page}`)
    .then(res => dispatch({ type: GET_NEW_VIDEOS_SUCCESS, res, page }))
    .catch(() => dispatch({ type: GET_NEW_VIDEOS_FAILURE }));
};

export const get_my_vtuber_list_videos = page => dispatch => {
  dispatch({ type: GET_MY_VTUBER_LIST_VIDEOS_REQUEST });
  setAuthKeys();

  return axios
    .get(`${config.backend_api_url}/my_vtuber_lists/videos/new?page=${page}`)
    .then(res =>
      dispatch({ type: GET_MY_VTUBER_LIST_VIDEOS_SUCCESS, res, page })
    )
    .catch(e => {
      console.log(e);
      dispatch({ type: GET_MY_VTUBER_LIST_VIDEOS_FAILURE });
    });
};
