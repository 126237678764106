/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { check_notifications } from "./actions/user";
import LazyFallback from "./LazyFallback";

const Home = React.lazy(() => import("./components/pages/Home"));
const Terms = React.lazy(() => import("./components/pages/Terms"));
const Privacy = React.lazy(() => import("./components/pages/Privacy"));
const Logout = React.lazy(() => import("./components/pages/Logout"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Registration = React.lazy(() =>
  import("./components/pages/Registration")
);
const About = React.lazy(() => import("./components/pages/About"));
const NotFound = React.lazy(() => import("./components/pages/NotFound"));
const Search = React.lazy(() => import("./components/pages/Search"));
const Settings = React.lazy(() => import("./components/pages/Settings"));
const Feedback = React.lazy(() => import("./components/pages/Feedback"));
const Help = React.lazy(() => import("./components/pages/Help"));
const Vtuber = React.lazy(() => import("./components/pages/Vtuber"));
const VtuberGroup = React.lazy(() => import("./components/pages/VtuberGroup"));
const User = React.lazy(() => import("./components/pages/User"));
const MyPage = React.lazy(() => import("./components/pages/MyPage"));
const IntroductionNew = React.lazy(() =>
  import("./components/pages/IntroductionNew")
);
const Introduction = React.lazy(() =>
  import("./components/pages/Introduction")
);
const DeleteUser = React.lazy(() => import("./components/pages/DeleteUser"));
const PasswordChange = React.lazy(() =>
  import("./components/pages/PasswordChange")
);
const PasswordReset = React.lazy(() =>
  import("./components/pages/PasswordReset")
);
const VTuberRegistration = React.lazy(() =>
  import("./components/pages/VTuberRegistration")
);
const Feed = React.lazy(() => import("./components/pages/Feed"));
const Tags = React.lazy(() => import("./components/pages/Tags"));
const NewVTuber = React.lazy(() => import("./components/pages/NewVTuber"));
const Notifications = React.lazy(() =>
  import("./components/pages/Notifications")
);
const Clip = React.lazy(() => import("./components/pages/Clip"));
const ClipNew = React.lazy(() => import("./components/pages/ClipNew"));
const VTuberTweet = React.lazy(() => import("./components/pages/VTuberTweet"));
const Embed = React.lazy(() => import("./components/pages/Embed"));
const Trending = React.lazy(() => import("./components/pages/Trending"));

const Page = (Component, ogp_enabled = true) => (props) => {
  const dispatch = useDispatch();
  const { isSignedIn } = useSelector(
    (state) => state.reduxTokenAuth.currentUser
  );

  React.useEffect(() => {
    isSignedIn && dispatch(check_notifications());
  }, [dispatch, isSignedIn]);

  return (
    <>
      {ogp_enabled && (
        <Helmet>
          <meta name="twitter:card" content="summary" />
          <meta
            property="og:image"
            content="https://vtubers-image.s3-ap-northeast-1.amazonaws.com/vtubers-favicon-twitter.png"
          />
          <meta
            property="og:title"
            content="VTubers - VTuberのクリップ共有サービス"
          />
          <meta
            property="og:description"
            content="「VTubers」はVTuberファンのためのクリップ共有サービスです。"
          />
          <meta property="og:url" content="https://vtubers.love" />
          <meta content="@VTubersOfficial" name="twitter:site" />
          <meta content="@VTubersOfficial" name="twitter:creator" />
        </Helmet>
      )}
      <Component {...props} />
    </>
  );
};

export default (props) => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<LazyFallback />}>
        <Switch>
          <Route exact path="/" component={Page(Home)} />
          <Route exact path="/trending" component={Page(Trending)} />
          <Route exact path="/feed" component={Page(Feed)} />
          <Route
            exact
            path="/forum/introductions/new"
            component={Page(IntroductionNew)}
          />
          <Route
            exact
            path="/forum/introductions/:id"
            component={Page(Introduction, false)}
          />
          <Route exact path="/embed/:id" component={Page(Embed)} />
          <Route exact path="/search" component={Page(Search)} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/password_change" component={PasswordChange} />
          <Route exact path="/password_reset" component={PasswordReset} />
          <Route exact path="/delete_user" component={DeleteUser} />
          <Route exact path="/settings" component={Page(Settings)} />
          <Route exact path="/feedback" component={Page(Feedback)} />
          <Route exact path="/help" component={Page(Help)} />
          <Route exact path="/terms" component={Page(Terms)} />
          <Route exact path="/privacy" component={Page(Privacy)} />
          <Route exact path="/about" component={Page(About)} />
          <Route
            exact
            path="/vtubers/:id/tweet"
            component={Page(VTuberTweet)}
          />
          <Route exact path="/vtubers/:id" component={Page(Vtuber, false)} />
          <Route exact path="/groups/:id" component={Page(VtuberGroup)} />
          <Route exact path="/users/:id" component={User} />
          <Route exact path="/forum/clips/new" component={Page(ClipNew)} />
          <Route exact path="/forum/clips/:id" component={Page(Clip, false)} />
          <Route exact path="/tags" component={Page(Tags)} />
          <Route exact path="/new_vtubers" component={Page(NewVTuber)} />
          <Route exact path="/notifications" component={Page(Notifications)} />
          <Route
            exact
            path="/vtuber_registration"
            component={Page(VTuberRegistration)}
          />
          <Route exact path="/mypage" component={Page(MyPage)} />
          <Route path="*" component={Page(NotFound)} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};
