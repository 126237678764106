import axios from "axios";
import config from "../config";
import { setAuthKeys } from "../auth";

export const GET_TRENDING_VTUBERS_REQUEST = "GET_TRENDING_VTUBERS_REQUEST";
export const GET_TRENDING_VTUBERS_SUCCESS = "GET_TRENDING_VTUBERS_SUCCESS";
export const GET_TRENDING_VTUBERS_FAILURE = "GET_TRENDING_VTUBERS_FAILURE";

export const GET_NEW_VTUBERS_REQUEST = "GET_NEW_VTUBERS_REQUEST";
export const GET_NEW_VTUBERS_SUCCESS = "GET_NEW_VTUBERS_SUCCESS";
export const GET_NEW_VTUBERS_FAILURE = "GET_NEW_VTUBERS_FAILURE";

export const GET_SUBSCRIBER_NUM_LOG_REQUEST = "GET_SUBSCRIBER_NUM_LOG_REQUEST";
export const GET_SUBSCRIBER_NUM_LOG_SUCCESS = "GET_SUBSCRIBER_NUM_LOG_SUCCESS";
export const GET_SUBSCRIBER_NUM_LOG_FAILURE = "GET_SUBSCRIBER_NUM_LOG_FAILURE";

export const GET_NEXT_BREAK_VTUBERS_REQUEST = "GET_NEXT_BREAK_VTUBERS_REQUEST";
export const GET_NEXT_BREAK_VTUBERS_SUCCESS = "GET_NEXT_BREAK_VTUBERS_SUCCESS";
export const GET_NEXT_BREAK_VTUBERS_FAILURE = "GET_NEXT_BREAK_VTUBERS_FAILURE";

export const GET_RELATED_VTUBERS_REQUEST = "GET_RELATED_VTUBERS_REQUEST";
export const GET_RELATED_VTUBERS_SUCCESS = "GET_RELATED_VTUBERS_SUCCESS";
export const GET_RELATED_VTUBERS_FAILURE = "GET_RELATED_VTUBERS_FAILURE";

export const GET_RECOMMENDED_VTUBERS_REQUEST =
  "GET_RECOMMENDED_VTUBERS_REQUEST";
export const GET_RECOMMENDED_VTUBERS_SUCCESS =
  "GET_RECOMMENDED_VTUBERS_SUCCESS";
export const GET_RECOMMENDED_VTUBERS_FAILURE =
  "GET_RECOMMENDED_VTUBERS_FAILURE";

export const GET_TRENDING_VTUBERS_VTUBERS_REQUEST =
  "GET_TRENDING_VTUBERS_VTUBERS_REQUEST";
export const GET_TRENDING_VTUBERS_VTUBERS_SUCCESS =
  "GET_TRENDING_VTUBERS_VTUBERS_SUCCESS";
export const GET_TRENDING_VTUBERS_VTUBERS_FAILURE =
  "GET_TRENDING_VTUBERS_VTUBERS_FAILURE";

export const GET_VTUBER_REQUEST = "GET_VTUBER_REQUEST";
export const GET_VTUBER_SUCCESS = "GET_VTUBER_SUCCESS";
export const GET_VTUBER_FAILURE = "GET_VTUBER_FAILURE";

export const GET_VTUBER_FOR_TWEET_REQUEST = "GET_VTUBER_FOR_TWEET_REQUEST";
export const GET_VTUBER_FOR_TWEET_SUCCESS = "GET_VTUBER_FOR_TWEET_SUCCESS";
export const GET_VTUBER_FOR_TWEET_FAILURE = "GET_VTUBER_FOR_TWEET_FAILURE";

export const GET_VTUBER_VIDEOS_REQUEST = "GET_VTUBER_VIDEOS_REQUEST";
export const GET_VTUBER_VIDEOS_SUCCESS = "GET_VTUBER_VIDEOS_SUCCESS";
export const GET_VTUBER_VIDEOS_FAILURE = "GET_VTUBER_VIDEOS_FAILURE";

export const RESET_VTUBER_VIDEOS = "RESET_VTUBER_VIDEOS";
export const RESET_VTUBER_NAME = "RESET_VTUBER_NAME";
export const RESET_VTUBER_NAME_FOR_TWEET = "RESET_VTUBER_NAME_FOR_TWEET";
export const RESET_RELATED_VTUBERS = "RESET_RELATED_VTUBERS";
export const RESET_NEXT_BREAK_VTUBERS = "RESET_NEXT_BREAK_VTUBERS";

export const SET_NEXT_BREAK_LIMIT = "SET_NEXT_BREAK_LIMIT";

export const GET_VTUBERS_CLIPS_REQUEST = "GET_VTUBERS_CLIPS_REQUEST";
export const GET_VTUBERS_CLIPS_SUCCESS = "GET_VTUBERS_CLIPS_SUCCESS";
export const GET_VTUBERS_CLIPS_FAILURE = "GET_VTUBERS_CLIPS_FAILURE";

export const RESET_VTUBERS_CLIPS = "RESET_VTUBERS_CLIPS";

export const SET_VTUBERS_CLIPS_ORDER = "SET_VTUBERS_CLIPS_ORDER";

export const RESET_TRENDING_VTUBERS = "RESET_TRENDING_VTUBERS";
export const SET_TRENDING_VTUBERS_ORDER = "SET_TRENDING_VTUBERS_ORDER";

export const reset_trending_vtubers = () => (dispatch) => {
  dispatch({ type: RESET_TRENDING_VTUBERS });
};

export const set_trending_vtubers_order = (order) => (dispatch) => {
  dispatch({ type: SET_TRENDING_VTUBERS_ORDER, order });
};

export const get_vtubers_clips = (name) => (page) => (dispatch, getState) => {
  const state = getState();

  if (state.vtuber.clips.state === "request") {
    return;
  }

  dispatch({ type: GET_VTUBERS_CLIPS_REQUEST });

  const { order } = state.vtuber.clips;

  return axios
    .get(
      `${config.backend_api_url}/search?t%5B%5D=${encodeURIComponent(
        name
      )}&w=clip&o=${order}&page=${page}`
    )
    .then((res) => dispatch({ type: GET_VTUBERS_CLIPS_SUCCESS, res, page }))
    .catch(() => dispatch({ type: GET_VTUBERS_CLIPS_FAILURE }));
};

export const reset_vtubers_clips = () => (dispatch) => {
  dispatch({ type: RESET_VTUBERS_CLIPS });
};

export const set_vtubers_clips_order = (order) => (dispatch) => {
  dispatch({ type: SET_VTUBERS_CLIPS_ORDER, order });
};

export const get_trending_vtubers = (page) => async (dispatch, getState) => {
  dispatch({ type: GET_TRENDING_VTUBERS_REQUEST });
  setAuthKeys();

  const { order } = getState().vtuber.trending;

  try {
    const res = await axios.get(
      `${config.backend_api_url}/vtubers/trending?page=${page}&order=${order}`
    );
    return dispatch({ type: GET_TRENDING_VTUBERS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_TRENDING_VTUBERS_FAILURE });
  }
};

export const get_subscriber_num_log = (vtuber_id) => async (dispatch) => {
  dispatch({ type: GET_SUBSCRIBER_NUM_LOG_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/vtubers/${vtuber_id}/subscriber_num_log`
    );
    return dispatch({ type: GET_SUBSCRIBER_NUM_LOG_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: GET_SUBSCRIBER_NUM_LOG_FAILURE });
  }
};

export const get_new_vtubers = (page) => async (dispatch) => {
  dispatch({ type: GET_NEW_VTUBERS_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/vtubers/new?page=${page}`
    );
    return dispatch({ type: GET_NEW_VTUBERS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_NEW_VTUBERS_FAILURE });
  }
};

export const get_next_break_vtubers = (page) => async (dispatch, getState) => {
  const state = getState();

  if (state.vtuber.next_break.state === "request") {
    return;
  }

  dispatch({ type: GET_NEXT_BREAK_VTUBERS_REQUEST });
  setAuthKeys();

  const { limit } = state.vtuber.next_break;

  try {
    const res = await axios.get(
      `${config.backend_api_url}/vtubers/next_break?page=${page}&limit=${limit}`
    );
    return dispatch({ type: GET_NEXT_BREAK_VTUBERS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_NEXT_BREAK_VTUBERS_FAILURE });
  }
};

export const get_recommended_vtubers = (page) => (dispatch) => {
  dispatch({ type: GET_RECOMMENDED_VTUBERS_REQUEST });
  setAuthKeys();

  return axios
    .get(`${config.backend_api_url}/users/vtubers/recommended?page=${page}`)
    .then((res) =>
      dispatch({ type: GET_RECOMMENDED_VTUBERS_SUCCESS, res, page })
    )
    .catch(() => dispatch({ type: GET_RECOMMENDED_VTUBERS_FAILURE }));
};

export const get_trending_vtubers_vtubers = (page) => (dispatch) => {
  dispatch({ type: GET_TRENDING_VTUBERS_VTUBERS_REQUEST });
  setAuthKeys();

  return axios
    .get(
      `${config.backend_api_url}/vtubers/my_vtuber_list/trending?page=${page}`
    )
    .then((res) =>
      dispatch({ type: GET_TRENDING_VTUBERS_VTUBERS_SUCCESS, res, page })
    )
    .catch(() => dispatch({ type: GET_TRENDING_VTUBERS_VTUBERS_FAILURE }));
};

export const get_vtuber = (vtuber_id) => (dispatch) => {
  dispatch({ type: GET_VTUBER_REQUEST });
  setAuthKeys();

  return axios
    .get(`${config.backend_api_url}/vtubers/${vtuber_id}`)
    .then((res) => dispatch({ type: GET_VTUBER_SUCCESS, res }))
    .catch(() => dispatch({ type: GET_VTUBER_FAILURE }));
};

export const get_vtuber_for_tweet = (vtuber_id) => (dispatch) => {
  dispatch({ type: GET_VTUBER_FOR_TWEET_REQUEST });
  setAuthKeys();

  return axios
    .get(`${config.backend_api_url}/vtubers/${vtuber_id}/tweet`)
    .then((res) => dispatch({ type: GET_VTUBER_FOR_TWEET_SUCCESS, res }))
    .catch(() => dispatch({ type: GET_VTUBER_FOR_TWEET_FAILURE }));
};

export const get_vtuber_videos = (name) => (page) => (dispatch) => {
  dispatch({ type: GET_VTUBER_VIDEOS_REQUEST });

  return axios
    .get(
      `${config.backend_api_url}/search?t%5B%5D=${encodeURIComponent(
        name
      )}&w=video&o=published_at&page=${page}`
    )
    .then((res) => dispatch({ type: GET_VTUBER_VIDEOS_SUCCESS, res, page }))
    .catch(() => dispatch({ type: GET_VTUBER_VIDEOS_FAILURE }));
};

export const get_related_vtubers = (vtuber_id) => (page) => (dispatch) => {
  dispatch({ type: GET_RELATED_VTUBERS_REQUEST });
  setAuthKeys();

  return axios
    .get(`${config.backend_api_url}/vtubers/${vtuber_id}/related?page=${page}`)
    .then((res) => dispatch({ type: GET_RELATED_VTUBERS_SUCCESS, res, page }))
    .catch(() => dispatch({ type: GET_RELATED_VTUBERS_FAILURE }));
};

export const reset_vtuber_videos = () => (dispatch) => {
  dispatch({ type: RESET_VTUBER_VIDEOS });
};

export const reset_vtuber_name = () => (dispatch) => {
  dispatch({ type: RESET_VTUBER_NAME });
};

export const reset_vtuber_name_for_tweet = () => (dispatch) => {
  dispatch({ type: RESET_VTUBER_NAME_FOR_TWEET });
};

export const reset_related_vtubers = () => (dispatch) => {
  dispatch({ type: RESET_RELATED_VTUBERS });
};

export const reset_next_break_vtubers = () => (dispatch) => {
  dispatch({ type: RESET_NEXT_BREAK_VTUBERS });
};

export const set_next_break_limit = (limit) => (dispatch) => {
  dispatch({ type: SET_NEXT_BREAK_LIMIT, limit });
};
