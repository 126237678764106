import { generateAuthActions } from "redux-token-auth";

import config from "./config";

const reduxTokenAuthconfig = {
  authUrl: config.backend_api_url + "/auth",
  userAttributes: {
    id: "id",
    name: "name",
    image: "image",
    description: "description",
    twitter_id: "twitter_id",
    avatar: "avatar"
  },
  userRegistrationAttributes: { name: "name" }
};

const {
  registerUser,
  signInUser,
  signOutUser,
  signOutUserLocal,
  verifyCredentials,
  resetErrors
} = generateAuthActions(reduxTokenAuthconfig);

export {
  registerUser,
  signInUser,
  signOutUser,
  signOutUserLocal,
  verifyCredentials,
  resetErrors
};
