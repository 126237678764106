import { CHANGE_TAB } from "../actions/tab";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TAB:
      return { ...state, [action.tab_type]: { state: action.to } };
    default:
      return { ...state };
  }
};
