import axios from "axios";
import config from "../config";
import { setAuthKeys } from "../auth";

export const GET_VTUBER_GROUP_REQUEST = "GET_VTUBER_GROUP_REQUEST";
export const GET_VTUBER_GROUP_SUCCESS = "GET_VTUBER_GROUP_SUCCESS";
export const GET_VTUBER_GROUP_FAILURE = "GET_VTUBER_GROUP_FAILURE";

export const GET_GROUP_VTUBERS_REQUEST = "GET_GROUP_VTUBERS_REQUEST";
export const GET_GROUP_VTUBERS_SUCCESS = "GET_GROUP_VTUBERS_SUCCESS";
export const GET_GROUP_VTUBERS_FAILURE = "GET_GROUP_VTUBERS_FAILURE";

export const RESET_VTUBER_GROUP = "RESET_VTUBER_GROUP";
export const RESET_GROUP_VTUBERS = "RESET_GROUP_VTUBERS";

export const SET_GROUP_VTUBERS_ORDER = "SET_GROUP_VTUBERS_ORDER";

export const get_vtuber_group = (id) => async (dispatch) => {
  dispatch({ type: GET_VTUBER_GROUP_REQUEST });

  try {
    const res = await axios.get(
      `${config.backend_api_url}/vtuber_groups/${id}`
    );
    return dispatch({ type: GET_VTUBER_GROUP_SUCCESS, res });
  } catch (e) {
    console.error(e);
    return dispatch({ type: GET_VTUBER_GROUP_FAILURE });
  }
};

export const get_group_vtubers = (id) => (page) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_GROUP_VTUBERS_REQUEST });
  setAuthKeys();

  const { order } = getState().vtuber_group.vtubers;

  try {
    const res = await axios.get(
      `${config.backend_api_url}/vtuber_groups/${id}/vtubers?page=${page}&order=${order}`
    );
    return dispatch({ type: GET_GROUP_VTUBERS_SUCCESS, res, page });
  } catch (e) {
    console.error(e);
    return dispatch({ type: GET_GROUP_VTUBERS_FAILURE });
  }
};

export const reset_vtuber_group = () => (dispatch) => {
  dispatch({ type: RESET_VTUBER_GROUP });
};

export const reset_group_vtubers = () => (dispatch) => {
  dispatch({ type: RESET_GROUP_VTUBERS });
};

export const set_group_vtubers_order = (order) => (dispatch) => {
  dispatch({ type: SET_GROUP_VTUBERS_ORDER, order });
};
