import React from "react";
import styled from "styled-components";
import Badge from "@material-ui/core/Badge";
import { useSelector } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import {
  Close,
  SettingsOutlined,
  FeedbackOutlined,
  AssignmentIndOutlined,
  FavoriteBorder,
  HelpOutlineOutlined,
  NewReleasesOutlined,
  NotificationsOutlined,
  NoteAddOutlined,
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Link from "../atoms/Link";
import "./MuiDrawer.css";

const StyledListItem = styled(ListItem)`
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
`;

export default (props) => {
  const { isSignedIn, attributes } = useSelector(
    (state) => state.reduxTokenAuth.currentUser
  );
  const { not_read_notifications_count } = useSelector(
    (state) => state.user.my_account
  );
  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <List
        style={{
          width: "18rem",
          maxWidth: "70vw",
          paddingTop: 0,
        }}
      >
        <StyledListItem
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            height: "54px",
          }}
        >
          <ListItemIcon
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton onClick={props.onClose}>
              <Close />
            </IconButton>
          </ListItemIcon>
        </StyledListItem>
        <Divider />
        {isSignedIn ? (
          <>
            <Link to="/mypage">
              <StyledListItem>
                <ListItemIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    src={attributes.avatar}
                    style={{ width: "38px", height: "38px" }}
                  />
                </ListItemIcon>
                <ListItemText>マイページ</ListItemText>
              </StyledListItem>
            </Link>
            <Link to="/notifications">
              <StyledListItem>
                {not_read_notifications_count &&
                not_read_notifications_count > 0 ? (
                  <ListItemIcon
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Badge
                      badgeContent={not_read_notifications_count}
                      color="primary"
                    >
                      <NotificationsOutlined />
                    </Badge>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <NotificationsOutlined />
                  </ListItemIcon>
                )}
                <ListItemText>通知</ListItemText>
              </StyledListItem>
            </Link>
          </>
        ) : (
          <>
            <Link to="/login">
              <StyledListItem>
                <ListItemIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SettingsOutlined style={{ opacity: 0 }} />
                </ListItemIcon>
                <ListItemText>ログイン</ListItemText>
              </StyledListItem>
            </Link>
            <Link to="/registration">
              <StyledListItem>
                <ListItemIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SettingsOutlined style={{ opacity: 0 }} />
                </ListItemIcon>
                <ListItemText>アカウント作成</ListItemText>
              </StyledListItem>
            </Link>
          </>
        )}
        <Divider style={{ margin: "8px 0 " }} />
        <Link to="/forum/clips/new">
          <StyledListItem>
            <ListItemIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NoteAddOutlined />
            </ListItemIcon>
            <ListItemText>クリップの新規作成</ListItemText>
          </StyledListItem>
        </Link>
        <Link to="/forum/introductions/new">
          <StyledListItem>
            <ListItemIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FavoriteBorder />
            </ListItemIcon>
            <ListItemText>VTuberの紹介</ListItemText>
          </StyledListItem>
        </Link>
        <Link to="/vtuber_registration">
          <StyledListItem>
            <ListItemIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AssignmentIndOutlined />
            </ListItemIcon>
            <ListItemText>VTuberの登録申請</ListItemText>
          </StyledListItem>
        </Link>
        <Link to="/new_vtubers">
          <StyledListItem>
            <ListItemIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NewReleasesOutlined />
            </ListItemIcon>
            <ListItemText>新規登録されたVTuber</ListItemText>
          </StyledListItem>
        </Link>
        {isSignedIn ? (
          <Link to="/settings">
            <StyledListItem>
              <ListItemIcon
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SettingsOutlined />
              </ListItemIcon>
              <ListItemText>設定</ListItemText>
            </StyledListItem>
          </Link>
        ) : null}
        <Link to="/feedback">
          <StyledListItem>
            <ListItemIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FeedbackOutlined />
            </ListItemIcon>
            <ListItemText>フィードバック</ListItemText>
          </StyledListItem>
        </Link>
        <Link to="/help">
          <StyledListItem>
            <ListItemIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HelpOutlineOutlined />
            </ListItemIcon>
            <ListItemText>ヘルプ</ListItemText>
          </StyledListItem>
        </Link>
        <Divider style={{ margin: "8px 0 " }} />
        <Link to="/about">
          <StyledListItem>
            <ListItemIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SettingsOutlined style={{ opacity: 0 }} />
            </ListItemIcon>
            <ListItemText>VTubersについて</ListItemText>
          </StyledListItem>
        </Link>
        <Link to="/privacy">
          <StyledListItem>
            <ListItemIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SettingsOutlined style={{ opacity: 0 }} />
            </ListItemIcon>
            <ListItemText>プライバシーポリシー</ListItemText>
          </StyledListItem>
        </Link>
        <Link to="/terms">
          <StyledListItem>
            <ListItemIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SettingsOutlined style={{ opacity: 0 }} />
            </ListItemIcon>
            <ListItemText>利用規約</ListItemText>
          </StyledListItem>
        </Link>
        {isSignedIn && (
          <>
            <Divider style={{ margin: "8px 0" }} />
            <Link to="/logout">
              <StyledListItem>
                <ListItemIcon
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SettingsOutlined style={{ opacity: 0 }} />
                </ListItemIcon>
                <ListItemText>ログアウト</ListItemText>
              </StyledListItem>
            </Link>
          </>
        )}
        <div style={{ opacity: 0 }}>
          <StyledListItem>
            <ListItemIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SettingsOutlined style={{ opacity: 0 }} />
            </ListItemIcon>
            <ListItemText>利用規約</ListItemText>
          </StyledListItem>
        </div>
      </List>
    </Drawer>
  );
};
