import {
  GET_VTUBER_GROUP_REQUEST,
  GET_VTUBER_GROUP_SUCCESS,
  GET_VTUBER_GROUP_FAILURE,
  GET_GROUP_VTUBERS_REQUEST,
  GET_GROUP_VTUBERS_SUCCESS,
  GET_GROUP_VTUBERS_FAILURE,
  RESET_VTUBER_GROUP,
  RESET_GROUP_VTUBERS,
  SET_GROUP_VTUBERS_ORDER,
} from "../actions/vtuber_group";

const initialState = {
  state: "sleep",
  name: "",
  image: "",
  description: "",
  twitter_id: "",
  youtube_channel_id: "",

  vtubers: {
    state: "sleep",
    order: "s",
    items: [],
    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_VTUBER_GROUP:
      return {
        ...state,
        name: "",
        image: "",
        description: "",
        twitter_id: "",
        youtube_channel_id: "",

        vtubers: {
          ...state.vtubers,
          items: [],
          has_more: true,
          page: 0,
          length: 0,
          max_length: 0,
        },
      };
    case GET_VTUBER_GROUP_REQUEST:
      return {
        ...state,
        state: "request",
      };
    case GET_VTUBER_GROUP_SUCCESS:
      return {
        ...state,
        ...action.res.data,
        state: "success",
      };
    case GET_VTUBER_GROUP_FAILURE:
      return {
        ...state,
        state: "failure",
        has_more: false,
      };
    case GET_GROUP_VTUBERS_REQUEST:
      return {
        ...state,
        vtubers: {
          ...state.vtubers,
          state: "request",
        },
      };
    case GET_GROUP_VTUBERS_SUCCESS:
      return {
        ...state,
        vtubers: {
          ...state.vtubers,
          state: "success",
          items: [...state.vtubers.items, ...action.res.data.items],
          has_more: action.res.data.items.length > 0,
          page: action.page,
        },
      };
    case GET_GROUP_VTUBERS_FAILURE:
      return {
        ...state,
        vtubers: {
          ...state.vtubers,
          state: "failure",
          has_more: false,
        },
      };
    case SET_GROUP_VTUBERS_ORDER:
      return {
        ...state,
        vtubers: {
          ...state.vtubers,
          order: action.order,
        },
      };
    case RESET_GROUP_VTUBERS:
      return {
        ...state,
        vtubers: {
          ...state.vtubers,
          items: [],
          has_more: true,
          page: 0,
          length: 0,
          max_length: 0,
        },
      };
    default:
      return { ...state };
  }
};
