import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import reducers from "./reducers";

const initialState = {};
const reduxDevtoolsExtensionCompose =
  "__REDUX_DEVTOOLS_EXTENSION_COMPOSE__" in window &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25
  });
const composeEnhancers =
  typeof reduxDevtoolsExtensionCompose === "function"
    ? reduxDevtoolsExtensionCompose
    : compose;
const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
