import React from "react";
import { useSelector } from "react-redux";
import Badge from "@material-ui/core/Badge";
import Wrapper from "../atoms/Wrapper";
import Logo from "../atoms/Logo";
import styled from "styled-components";
import Link from "../atoms/Link";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import { AccountCircle, NoteAddOutlined, OpenInNewOutlined } from "@material-ui/icons";

const StyledWrapper = styled(Wrapper)`
  position: sticky;
  height: 50px;
  background: ${(props) => props.theme.colors.white};
  top: 0;
  padding: 0 0.9rem;
  justify-content: space-between;
  z-index: 100;
  box-shadow: 0 1px 2px ${(props) => props.theme.colors.shadow};

  @media (max-width: 450px) {
    padding: 0 0.2rem 0 0.5rem;
  }
`;

const RightObjWrapper = styled.div`
  margin-right: 0.5rem;
  display: flex;
`;

const StyledA = styled.a`
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  margin: 0 28px 0 0;
`;

const StyledListItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

const OtherSitesLink = styled.span`
  color: ${(props) => props.theme.colors.primary};
  letter-spacing: 0.05rem;
  font-size: 0.95rem;
`;

const StyledLinkText = styled.div`
`;

const Header = (props) => {
  const { isSignedIn, attributes } = useSelector(
    (state) => state.reduxTokenAuth.currentUser
  );
  const { not_read_notifications_count } = useSelector(
    (state) => state.user.my_account
  );
  return (
    <StyledWrapper>
      <Wrapper>
        <Link to={"/"}>
          <Logo />
        </Link>
      </Wrapper>
      <Wrapper>
        {isSignedIn ? (
          <>
            <RightObjWrapper>
              <StyledA
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.nijiuta.com"
              >
                <StyledListItemIcon>
                  <OpenInNewOutlined
                    style={{ width: 22, height: 22 }}
                    color="primary"
                  />
                </StyledListItemIcon>
                <StyledLinkText>
                  <OtherSitesLink>NIJIUTA</OtherSitesLink>
                </StyledLinkText>
              </StyledA>
              <Link to="/forum/clips/new">
                <Button
                  variant="text"
                  color="primary"
                  startIcon={
                    <NoteAddOutlined style={{ marginRight: "-3px" }} />
                  }
                >
                  クリップ作成
                </Button>
              </Link>
            </RightObjWrapper>
            <RightObjWrapper>
              <ButtonBase
                onClick={props.open_right_drawer}
                style={{
                  borderRadius: "9999px",
                  // padding: "3px",
                  marginLeft: "13px",
                  width: "34px",
                  height: "34px",
                }}
              >
                {not_read_notifications_count &&
                not_read_notifications_count > 0 ? (
                  <Badge
                    badgeContent={not_read_notifications_count}
                    color="primary"
                    overlap="circle"
                  >
                    <Avatar
                      src={attributes.avatar}
                      style={{ width: "30px", height: "30px" }}
                    />
                  </Badge>
                ) : (
                  <Avatar
                    src={attributes.avatar}
                    style={{ width: "30px", height: "30px" }}
                  />
                )}
              </ButtonBase>
            </RightObjWrapper>
          </>
        ) : (
          <>
            <RightObjWrapper>
              <StyledA
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.nijiuta.com"
              >
                <StyledListItemIcon>
                  <OpenInNewOutlined
                    style={{ width: 22, height: 22 }}
                    color="primary"
                  />
                </StyledListItemIcon>
                <StyledLinkText>
                  <OtherSitesLink>NIJIUTA</OtherSitesLink>
                </StyledLinkText>
              </StyledA>
              <Link to="/forum/clips/new">
                <Button
                  variant="text"
                  color="primary"
                  startIcon={
                    <NoteAddOutlined style={{ marginRight: "-3px" }} />
                  }
                >
                  クリップ作成
                </Button>
              </Link>
            </RightObjWrapper>
            <RightObjWrapper>
              <IconButton
                onClick={props.open_right_drawer}
                style={{ width: "34px", height: "34px" }}
              >
                <AccountCircle />
              </IconButton>
            </RightObjWrapper>
          </>
        )}
      </Wrapper>
    </StyledWrapper>
  );
};

export default Header;
