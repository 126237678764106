import axios from "axios";
import config from "../config";
import { setAuthKeys } from "../auth";

export const POST_REPORT_REQUEST = "POST_REPORT_REQUEST";
export const POST_REPORT_SUCCESS = "POST_REPORT_SUCCESS";
export const POST_REPORT_FAILURE = "POST_REPORT_FAILURE";

export const SET_REPORT_STATE = "SET_REPORT_STATE";

export const post_report = (message, type) => async dispatch => {
  dispatch({ type: POST_REPORT_REQUEST });
  setAuthKeys();

  try {
    await axios.post(`${config.backend_api_url}/reports`, {
      report: {
        message,
        report_type: type
      }
    });
    return dispatch({ type: POST_REPORT_SUCCESS });
  } catch (e) {
    return dispatch({ type: POST_REPORT_FAILURE });
  }
};

export const set_report_state = state => dispatch => {
  dispatch({ type: SET_REPORT_STATE, state });
};
