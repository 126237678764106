import {
  POST_VTUBER_REGISTRATION_REQUEST,
  POST_VTUBER_REGISTRATION_SUCCESS,
  POST_VTUBER_REGISTRATION_FAILURE,
  SET_VTUBER_REGISTRATION_STATE
} from "../actions/vtuber_registration";

const initialState = {
  state: "sleep",
  errors: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_VTUBER_REGISTRATION_REQUEST:
      return { ...state, state: "request" };
    case POST_VTUBER_REGISTRATION_SUCCESS:
      return { ...state, state: "success" };
    case POST_VTUBER_REGISTRATION_FAILURE:
      return { ...state, state: "failure", errors: action.errors.data };
    case SET_VTUBER_REGISTRATION_STATE:
      return {
        ...state,
        state: action.state
      };
    default:
      return { ...state };
  }
};
