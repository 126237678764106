import { Link } from "react-router-dom";
import styled from "styled-components";

export default styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  color: inherit;
  padding-left: 0.2rem;
  padding-right: 0.2rem;

  :hover {
    color: inherit;
  }
`;
