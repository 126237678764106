import React from "react"; // eslint-disable-line global-require
import qs from "qs";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { verifyCredentials } from "./redux-token-auth-config";

import Routes from "./Routes";
import store from "./store";
import theme from "./theme.json";

const muiTheme = createMuiTheme({
  palette: {
    ...theme,
    primary: { main: "#2089f1" },
    secondary: { main: "#ff9800" },
  },
});

const App = (props) => {
  React.useEffect(() => {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    verifyCredentials(store, queryParams);
    dayjs.locale("ja");
    dayjs.extend(timezone);
    dayjs.extend(utc);
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={store}>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </MuiThemeProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
