import axios from "axios";
import config from "../config";
import { setAuthKeys } from "../auth";

export const UPDATE_TAGS_REQUEST = "UPDATE_TAGS_REQUEST";
export const UPDATE_TAGS_SUCCESS = "UPDATE_TAGS_SUCCESS";
export const UPDATE_TAGS_FAILURE = "UPDATE_TAGS_FAILURE";

export const GET_POPULAR_VTUBER_TAGS_REQUEST =
  "GET_POPULAR_VTUBER_TAGS_REQUEST";
export const GET_POPULAR_VTUBER_TAGS_SUCCESS =
  "GET_POPULAR_VTUBER_TAGS_SUCCESS";
export const GET_POPULAR_VTUBER_TAGS_FAILURE =
  "GET_POPULAR_VTUBER_TAGS_FAILURE";

export const GET_POPULAR_VIDEO_TAGS_REQUEST = "GET_POPULAR_VIDEO_TAGS_REQUEST";
export const GET_POPULAR_VIDEO_TAGS_SUCCESS = "GET_POPULAR_VIDEO_TAGS_SUCCESS";
export const GET_POPULAR_VIDEO_TAGS_FAILURE = "GET_POPULAR_VIDEO_TAGS_FAILURE";

export const update_tags = (tag_type, id, tags) => dispatch => {
  dispatch({ type: UPDATE_TAGS_REQUEST });
  setAuthKeys();

  return axios
    .patch(`${config.backend_api_url}/${tag_type}/${id}/tags`, {
      tags
    })
    .then(res => dispatch({ type: UPDATE_TAGS_SUCCESS }))
    .catch(() => dispatch({ type: UPDATE_TAGS_FAILURE }));
};

export const get_popular_vtuber_tags = page => dispatch => {
  dispatch({ type: GET_POPULAR_VTUBER_TAGS_REQUEST });
  setAuthKeys();

  return axios
    .get(`${config.backend_api_url}/vtubers/popular/tags?page=${page}`)
    .then(res => dispatch({ type: GET_POPULAR_VTUBER_TAGS_SUCCESS, res, page }))
    .catch(() => dispatch({ type: GET_POPULAR_VTUBER_TAGS_FAILURE }));
};

export const get_popular_video_tags = page => dispatch => {
  dispatch({ type: GET_POPULAR_VIDEO_TAGS_REQUEST });
  setAuthKeys();

  return axios
    .get(`${config.backend_api_url}/videos/popular/tags?page=${page}`)
    .then(res => dispatch({ type: GET_POPULAR_VIDEO_TAGS_SUCCESS, res, page }))
    .catch(() => dispatch({ type: GET_POPULAR_VIDEO_TAGS_FAILURE }));
};
