import {
  POST_REPORT_REQUEST,
  POST_REPORT_SUCCESS,
  POST_REPORT_FAILURE,
  SET_REPORT_STATE
} from "../actions/report";

const initialState = {
  state: "sleep"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_REPORT_REQUEST:
      return { ...state, state: "request" };
    case POST_REPORT_SUCCESS:
      return { ...state, state: "success" };
    case POST_REPORT_FAILURE:
      return { ...state, state: "failure" };
    case SET_REPORT_STATE:
      return { ...state, state: action.state };
    default:
      return { ...state };
  }
};
