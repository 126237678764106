import {
  SEARCH,
  SET_W,
  GET_SEARCH_RESULTS_REQUEST,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_FAILURE,
} from "../actions/search";

const initialState = {
  state: "sleep",
  title: "",
  w: "clip",

  tags: [],
  search_query: "",
  clip: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: false,
    max_length: 0,
  },
  vtuber: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: false,
    max_length: 0,
  },
  group: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: false,
    max_length: 0,
  },
  video: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: false,
    max_length: 0,
  },
  user: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: false,
    max_length: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_RESULTS_REQUEST:
      return {
        ...state,
        [state.w]: {
          ...state[state.w],
          state: "request",
        },
      };
    case GET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        [action.w]: {
          ...state[action.w],
          state: "success",
          items: [...state[action.w].items, ...action.res.data.items],
          length:
            action.res.data.items.length > 0
              ? action.res.data.length
              : state[action.w].length,
          has_more: action.res.data.items.length > 0,
          page: action.page,
        },
      };
    case GET_SEARCH_RESULTS_FAILURE:
      return {
        ...state,
        [action.w]: {
          ...state[action.w],
          state: "failure",
          has_more: false,
        },
      };
    case SET_W:
      return {
        ...state,
        w: action.w,
      };
    case SEARCH:
      return {
        ...state,
        search_query: action.search_query,
        tags: action.tags ? [...action.tags] : [],
        w: action.w,
        [action.w]: {
          ...state[state.w],
          items: [],
          page: 0,
          has_more: true,
          length: 0,
          max_length: 0,
        },
      };
    default:
      return { ...state };
  }
};
