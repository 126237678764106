import {
  GET_TRENDING_VIDEOS_REQUEST,
  GET_TRENDING_VIDEOS_SUCCESS,
  GET_TRENDING_VIDEOS_FAILURE,
  GET_NEW_VIDEOS_REQUEST,
  GET_NEW_VIDEOS_SUCCESS,
  GET_NEW_VIDEOS_FAILURE,
  GET_MY_VTUBER_LIST_VIDEOS_REQUEST,
  GET_MY_VTUBER_LIST_VIDEOS_SUCCESS,
  GET_MY_VTUBER_LIST_VIDEOS_FAILURE,
} from "../actions/video";

const initialState = {
  state: "sleep",
  title: "",
  tags: [],
  trending: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  new: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  my_vtuber_list: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRENDING_VIDEOS_REQUEST:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "request",
        },
      };
    case GET_TRENDING_VIDEOS_SUCCESS:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "success",
          items: [...state.trending.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_TRENDING_VIDEOS_FAILURE:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "failure",
          has_more: false,
        },
      };
    case GET_NEW_VIDEOS_REQUEST:
      return {
        ...state,
        new: {
          ...state.new,
          state: "request",
        },
      };
    case GET_NEW_VIDEOS_SUCCESS:
      return {
        ...state,
        new: {
          ...state.new,
          state: "success",
          items: [...state.new.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_NEW_VIDEOS_FAILURE:
      return {
        ...state,
        new: {
          ...state.new,
          state: "failure",
          has_more: false,
        },
      };
    case GET_MY_VTUBER_LIST_VIDEOS_REQUEST:
      return {
        ...state,
        my_vtuber_list: {
          ...state.my_vtuber_list,
          state: "request",
        },
      };
    case GET_MY_VTUBER_LIST_VIDEOS_SUCCESS:
      return {
        ...state,
        my_vtuber_list: {
          ...state.my_vtuber_list,
          state: "success",
          items: [...state.my_vtuber_list.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_MY_VTUBER_LIST_VIDEOS_FAILURE:
      return {
        ...state,
        my_vtuber_list: {
          ...state.my_vtuber_list,
          state: "failure",
          has_more: false,
        },
      };
    default:
      return { ...state };
  }
};
