import axios from "axios";
import config from "../config";
import { setAuthKeys } from "../auth";

export const POST_VTUBER_REGISTRATION_REQUEST =
  "POST_VTUBER_REGISTRATION_REQUEST";
export const POST_VTUBER_REGISTRATION_SUCCESS =
  "POST_VTUBER_REGISTRATION_SUCCESS";
export const POST_VTUBER_REGISTRATION_FAILURE =
  "POST_VTUBER_REGISTRATION_FAILURE";
export const SET_VTUBER_REGISTRATION_STATE = "SET_VTUBER_REGISTRATION_STATE";

export const post_vtuber_registration = ({
  name,
  youtube_channel_id,
  description,
  twitter_id,
  group_name,
  url,
  link_title
}) => dispatch => {
  dispatch({ type: POST_VTUBER_REGISTRATION_REQUEST });
  setAuthKeys();

  return axios
    .post(`${config.backend_api_url}/vtuber_registration`, {
      vtuber_registration: {
        name,
        youtube_channel_id,
        description,
        twitter_id,
        group_name,
        url,
        link_title
      }
    })
    .then(res => dispatch({ type: POST_VTUBER_REGISTRATION_SUCCESS, res }))
    .catch(errors =>
      dispatch({
        type: POST_VTUBER_REGISTRATION_FAILURE,
        errors: errors.response
      })
    );
};

export const set_vtuber_registration_state = state => dispatch => {
  dispatch({ type: SET_VTUBER_REGISTRATION_STATE, state });
};
