import {
  SET_BADGE_STATE,
  FOLLOW_REQUEST,
  FOLLOW_SUCCESS,
  FOLLOW_FAILURE,
  UNFOLLOW_REQUEST,
  UNFOLLOW_SUCCESS,
  UNFOLLOW_FAILURE,
  CHECK_NOTIFICATIONS_REQUEST,
  CHECK_NOTIFICATIONS_SUCCESS,
  CHECK_NOTIFICATIONS_FAILURE,
  GET_TRENDING_USERS_REQUEST,
  GET_TRENDING_USERS_SUCCESS,
  GET_TRENDING_USERS_FAILURE,
  GET_FOLLOW_USERS_REQUEST,
  GET_FOLLOW_USERS_SUCCESS,
  GET_FOLLOW_USERS_FAILURE,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  READ_NOTIFICAITONS_REQUEST,
  READ_NOTIFICAITONS_SUCCESS,
  READ_NOTIFICAITONS_FAILURE,
  PASSWORD_CHANGE_FAILURE,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  EDIT_USER_ATTRIBUTES_REQUEST,
  EDIT_USER_ATTRIBUTES_SUCCESS,
  EDIT_USER_ATTRIBUTES_FAILURE,
  GET_MY_VTUBER_LIST_REQUEST,
  GET_MY_VTUBER_LIST_SUCCESS,
  GET_MY_VTUBER_LIST_FAILURE,
  ADD_MY_VTUBER_LIST_REQUEST,
  ADD_MY_VTUBER_LIST_SUCCESS,
  ADD_MY_VTUBER_LIST_FAILURE,
  UPLOAD_USER_AVATAR_REQUEST,
  UPLOAD_USER_AVATAR_SUCCESS,
  UPLOAD_USER_AVATAR_FAILURE,
  REMOVE_MY_VTUBER_LIST_REQUEST,
  REMOVE_MY_VTUBER_LIST_SUCCESS,
  REMOVE_MY_VTUBER_LIST_FAILURE,
  RESET_MY_VTUBER_LIST,
  RESET_NOTIFICATIONS,
  RESET_TRENDING_USERS,
  SET_TRENDING_USERS_OPTION,
} from "../actions/user";

const initialState = {
  state: "sleep",
  name: "",
  description: "",
  twitter_id: "",
  avatar: "",
  tags: [],
  introduction_liked_count: 0,
  follower_count: 0,
  clip_liked_count: 0,
  my_vtuber_list_items: [],
  followees: [],
  follow_state: "not_changed",
  my_account: {
    state: "sleep",
    not_read_notifications_count: 0,
  },
  my_vtuber_list: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  trending: {
    option: "weekly",
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  follow: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  user: {
    my_vtuber_list: {
      state: "sleep",
      items: [],

      page: 0,
      length: 0,
      has_more: true,
      max_length: 0,
    },
  },
  reset_password: {
    email: "",
    state: "not_sent",
  },
  change_password: {
    state: "not_sent",
    error: undefined,
  },
  delete_user: {
    state: "not_sent",
  },
  notification: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  badge: {
    left_drawer: "unread",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BADGE_STATE:
      return {
        ...state,
        badge: {
          ...state.badge,
          ...action.state,
        },
      };
    case CHECK_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        my_account: {
          ...state.my_account,
          state: "request",
        },
      };
    case CHECK_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        my_account: {
          ...state.my_account,
          not_read_notifications_count:
            action.res.data.not_read_notifications_count,
          state: "success",
        },
      };
    case CHECK_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        my_account: {
          ...state.my_account,
          state: "failure",
        },
      };
    case READ_NOTIFICAITONS_REQUEST:
      return {
        ...state,
        notification: {
          ...state.notification,
          state: "request",
        },
      };
    case READ_NOTIFICAITONS_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          state: "success",
        },
      };
    case READ_NOTIFICAITONS_FAILURE:
      return {
        ...state,
        notification: {
          ...state.notification,
          state: "failure",
        },
      };
    case RESET_NOTIFICATIONS:
      return {
        ...state,
        notification: {
          ...state.notification,
          has_more: true,
          state: "sleep",
          page: 0,
          items: [],
        },
      };
    case GET_TRENDING_USERS_REQUEST:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "request",
        },
      };
    case GET_TRENDING_USERS_SUCCESS:
      if (action.option !== state.trending.option) {
        return state;
      }
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "success",
          items: [...state.trending.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_TRENDING_USERS_FAILURE:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "failure",
          has_more: false,
        },
      };
    case GET_FOLLOW_USERS_REQUEST:
      return {
        ...state,
        follow: {
          ...state.follow,
          state: "request",
        },
      };
    case GET_FOLLOW_USERS_SUCCESS:
      return {
        ...state,
        follow: {
          ...state.follow,
          state: "success",
          items: [...state.follow.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_FOLLOW_USERS_FAILURE:
      return {
        ...state,
        follow: {
          ...state.follow,
          state: "failure",
          has_more: false,
        },
      };
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notification: {
          ...state.notification,
          state: "request",
        },
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          state: "success",
          items: [...state.notification.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notification: {
          ...state.notification,
          state: "failure",
          has_more: false,
        },
      };
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        reset_password: {
          email: action.email,
          state: "request",
        },
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        reset_password: {
          email: action.email,
          state: "success",
          error: undefined,
        },
      };
    case PASSWORD_RESET_FAILURE:
      return {
        ...state,
        reset_password: {
          email: "",
          state: "failure",
          error: action.error,
        },
      };
    case PASSWORD_CHANGE_REQUEST:
      return {
        ...state,
        change_password: {
          state: "request",
        },
      };
    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        change_password: {
          state: "success",
          error: undefined,
        },
      };
    case PASSWORD_CHANGE_FAILURE:
      return {
        ...state,
        change_password: {
          state: "failure",
          error: action.error,
        },
      };
    case USER_DELETE_REQUEST:
      return {
        ...state,
        delete_user: {
          state: "request",
        },
      };
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        delete_user: {
          state: "success",
        },
      };
    case USER_DELETE_FAILURE:
      return {
        ...state,
        delete_user: {
          state: "failure",
        },
      };
    case RESET_MY_VTUBER_LIST:
      return {
        ...state,
        my_vtuber_list: {
          ...state.my_vtuber_list,
          state: state.my_vtuber_list.state === "request" ? "request" : "sleep",
          items: [],
          has_more: true,
          page: 0,
          length: 0,
          max_length: 0,
        },
        user: {
          ...state.user,
          my_vtuber_list: {
            ...state.user.my_vtuber_list,
            state:
              state.user.my_vtuber_list.state === "request"
                ? "request"
                : "sleep",
            items: [],
            has_more: true,
            page: 0,
            length: 0,
            max_length: 0,
          },
        },
      };
    case GET_USER_REQUEST:
      return {
        ...state,
        state: "request",
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        state: "success",
        name: action.res.data.name ? action.res.data.name : "",
        avatar: action.res.data.avatar,
        description: action.res.data.description
          ? action.res.data.description
          : "",
        twitter_id: action.res.data.twitter_id
          ? action.res.data.twitter_id
          : "",
        tags: action.res.data.tags,
        introduction_liked_count: action.res.data.introduction_liked_count,
        clip_liked_count: action.res.data.clip_liked_count,
        follower_count: action.res.data.follower_count,
        is_followed: action.res.data.is_followed,
        clip_count: action.res.data.clip_count,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        state: "failure",
      };
    case EDIT_USER_ATTRIBUTES_REQUEST:
      return {
        ...state,
      };
    case EDIT_USER_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        name: action.res.data.data.name,
        description: action.res.data.data.description,
        twitter_id: action.res.data.data.twitter_id,
      };
    case EDIT_USER_ATTRIBUTES_FAILURE:
      return {
        ...state,
      };
    case GET_MY_VTUBER_LIST_REQUEST:
      if (action.is_mine) {
        return {
          ...state,
          my_vtuber_list: {
            ...state.my_vtuber_list,
            state: "request",
          },
        };
      }
      return {
        ...state,
        user: {
          my_vtuber_list: {
            ...state.user.my_vtuber_list,
            state: "request",
          },
        },
      };
    case GET_MY_VTUBER_LIST_SUCCESS:
      if (action.is_mine) {
        return {
          ...state,
          my_vtuber_list: {
            ...state.my_vtuber_list,
            state: "success",
            items: [...state.my_vtuber_list.items, ...action.res.data],
            has_more: action.res.data.length > 0,
            page: action.page,
            length: action.res.data.length,
          },
        };
      }
      return {
        ...state,
        user: {
          ...state.user,
          my_vtuber_list: {
            ...state.user.my_vtuber_list,
            state: "success",
            items: [...state.user.my_vtuber_list.items, ...action.res.data],
            has_more: action.res.data.length > 0,
            page: action.page,
            length: action.res.data.length,
          },
        },
      };
    case GET_MY_VTUBER_LIST_FAILURE:
      if (action.is_mine) {
        return {
          ...state,
          my_vtuber_list: {
            ...state.my_vtuber_list,
            state: "failure",
            has_more: false,
          },
        };
      }
      return {
        ...state,
        user: {
          my_vtuber_list: {
            ...state.my_vtuber_list,
            state: "failure",
            has_more: false,
          },
        },
      };
    case FOLLOW_REQUEST:
      return {
        ...state,
      };
    case FOLLOW_SUCCESS:
      return {
        ...state,
        followees: [
          ...state.followees.filter(
            (item) => item.id !== action.followee_user_id
          ),
          { id: action.followee_user_id, state: "follow_success" },
        ],
      };
    case FOLLOW_FAILURE:
      return {
        ...state,
      };
    case UNFOLLOW_REQUEST:
      return {
        ...state,
      };
    case UNFOLLOW_SUCCESS:
      return {
        ...state,
        followees: [
          ...state.followees.filter(
            (item) => item.id !== action.followee_user_id
          ),
          { id: action.followee_user_id, state: "unfollow_success" },
        ],
      };
    case UNFOLLOW_FAILURE:
      return {
        ...state,
      };
    case ADD_MY_VTUBER_LIST_REQUEST:
      return {
        ...state,
        my_vtuber_list_items: [
          ...state.my_vtuber_list_items.filter((item) => item.id !== action.id),
          {
            id: action.id,
            state: "add_request",
          },
        ],
      };
    case ADD_MY_VTUBER_LIST_SUCCESS:
      return {
        ...state,
        my_vtuber_list_items: [
          ...state.my_vtuber_list_items.filter((item) => item.id !== action.id),
          {
            id: action.id,
            state: "add_success",
          },
        ],
      };
    case ADD_MY_VTUBER_LIST_FAILURE:
      return {
        ...state,
        my_vtuber_list_items: [
          ...state.my_vtuber_list_items.filter((item) => item.id !== action.id),
          {
            id: action.id,
            state: "add_failure",
          },
        ],
      };
    case UPLOAD_USER_AVATAR_REQUEST:
      return { ...state };
    case UPLOAD_USER_AVATAR_SUCCESS:
      return { ...state, avatar: action.res.data.avatar };
    case UPLOAD_USER_AVATAR_FAILURE:
      return { ...state };
    case REMOVE_MY_VTUBER_LIST_REQUEST:
      return {
        ...state,
        my_vtuber_list_items: [
          ...state.my_vtuber_list_items.filter((item) => item.id !== action.id),
          {
            id: action.id,
            state: "remove_request",
          },
        ],
      };
    case REMOVE_MY_VTUBER_LIST_SUCCESS:
      return {
        ...state,
        my_vtuber_list_items: [
          ...state.my_vtuber_list_items.filter((item) => item.id !== action.id),
          {
            id: action.id,
            state: "remove_success",
          },
        ],
      };
    case REMOVE_MY_VTUBER_LIST_FAILURE:
      return {
        ...state,
        my_vtuber_list_items: [
          ...state.my_vtuber_list_items.filter((item) => item.id !== action.id),
          {
            id: action.id,
            state: "remove_failure",
          },
        ],
      };
    case RESET_TRENDING_USERS:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case SET_TRENDING_USERS_OPTION:
      return {
        ...state,
        trending: {
          ...state.trending,
          option: action.option,
        },
      };
    default:
      return { ...state };
  }
};
