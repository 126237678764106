import {
  INCREMENT_LOOP_COUNT_REQUEST,
  INCREMENT_LOOP_COUNT_SUCCESS,
  INCREMENT_LOOP_COUNT_FAILURE,
  POST_CLIP_REQUEST,
  POST_CLIP_SUCCESS,
  POST_CLIP_FAILURE,
  GET_CLIP_REQUEST,
  GET_CLIP_SUCCESS,
  GET_CLIP_FAILURE,
  GET_MY_VTUBER_LIST_CLIPS_REQUEST,
  GET_MY_VTUBER_LIST_CLIPS_SUCCESS,
  GET_MY_VTUBER_LIST_CLIPS_FAILURE,
  GET_VTUBER_GROUP_CLIPS_REQUEST,
  GET_VTUBER_GROUP_CLIPS_SUCCESS,
  GET_VTUBER_GROUP_CLIPS_FAILURE,
  DELETE_CLIP_REQUEST,
  DELETE_CLIP_SUCCESS,
  DELETE_CLIP_FAILURE,
  GET_TRENDING_CLIPS_REQUEST,
  GET_TRENDING_CLIPS_SUCCESS,
  GET_USERS_CLIPS_FAILURE,
  GET_USERS_CLIPS_REQUEST,
  GET_USERS_CLIPS_SUCCESS,
  GET_USER_LIKE_CLIPS_FAILURE,
  GET_USER_LIKE_CLIPS_REQUEST,
  GET_USER_LIKE_CLIPS_SUCCESS,
  GET_TRENDING_CLIPS_FAILURE,
  GET_RELATED_CLIPS_REQUEST,
  GET_RELATED_CLIPS_SUCCESS,
  GET_RELATED_CLIPS_FAILURE,
  GET_FEED_CLIPS_REQUEST,
  GET_FEED_CLIPS_SUCCESS,
  GET_FEED_CLIPS_FAILURE,
  POST_CLIP_LIKE_REQUEST,
  POST_CLIP_LIKE_SUCCESS,
  POST_CLIP_LIKE_FAILURE,
  POST_CLIP_UNLIKE_REQUEST,
  POST_CLIP_UNLIKE_SUCCESS,
  POST_CLIP_UNLIKE_FAILURE,
  PUT_CLIP_REQUEST,
  PUT_CLIP_SUCCESS,
  PUT_CLIP_FAILURE,
  RESET_CLIP,
  RESET_FEED_CLIPS,
  RESET_RELATED_CLIPS,
  RESET_TRENDING_CLIPS,
  RESET_VTUBER_GROUP_CLIPS,
  RESET_USERS_CLIPS,
  RESET_USER_LIKE_CLIPS,
  SET_TRENDING_CLIPS_ORDER,
  SET_USERS_CLIPS_ORDER,
  SET_USER_LIKE_CLIPS_ORDER,
  SET_CLIP_STATE,
  SET_VTUBER_GROUP_CLIPS_ORDER,
  SET_MYLIST_FILTER_ENABLED,
} from "../actions/clip";

const initialState = {
  id: undefined,
  title: "",
  description: "",
  user: undefined,
  vtubers: [],
  clip_fragments: [],
  clip_likes_count: 0,
  loop_count: 0,
  is_liked: false,

  trending: {
    filter: false,
    order: "recommend",
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  feed: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  vtuber_group: {
    order: "recommend",
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  my_vtuber_list: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  users: {
    order: "created_at",
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  user_like: {
    order: "created_at",
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  related: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
  new: {
    state: "sleep",
  },
  delete: {
    state: "sleep",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MYLIST_FILTER_ENABLED:
      return {
        ...state,
        trending: {
          ...state.trending,
          filter: action.enabled,
        },
      };
    case INCREMENT_LOOP_COUNT_REQUEST:
      return { ...state };
    case INCREMENT_LOOP_COUNT_SUCCESS:
      return { ...state };
    case INCREMENT_LOOP_COUNT_FAILURE:
      return { ...state };
    case DELETE_CLIP_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          state: "request",
        },
      };
    case DELETE_CLIP_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          state: "success",
        },
      };
    case DELETE_CLIP_FAILURE:
      return {
        ...state,
        delete: {
          ...state.delete,
          state: "failure",
        },
      };
    case SET_CLIP_STATE:
      return {
        ...state,
        new: {
          ...state.new,
          state: action.state,
        },
      };
    case GET_VTUBER_GROUP_CLIPS_REQUEST:
      return {
        ...state,
        vtuber_group: {
          ...state.vtuber_group,
          state: "request",
        },
      };
    case GET_VTUBER_GROUP_CLIPS_SUCCESS:
      return {
        ...state,
        vtuber_group: {
          ...state.vtuber_group,
          state: "success",
          items: [...state.vtuber_group.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_VTUBER_GROUP_CLIPS_FAILURE:
      return {
        ...state,
        vtuber_group: {
          ...state.vtuber_group,
          state: "failure",
          has_more: false,
        },
      };
    case GET_RELATED_CLIPS_REQUEST:
      return {
        ...state,
        related: {
          ...state.related,
          state: "request",
        },
      };
    case GET_RELATED_CLIPS_SUCCESS:
      return {
        ...state,
        related: {
          ...state.related,
          state: "success",
          items: [...state.related.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_RELATED_CLIPS_FAILURE:
      return {
        ...state,
        related: {
          ...state.related,
          state: "failure",
          has_more: false,
        },
      };
    case GET_FEED_CLIPS_REQUEST:
      return {
        ...state,
        feed: {
          ...state.feed,
          state: "request",
        },
      };
    case GET_FEED_CLIPS_SUCCESS:
      return {
        ...state,
        feed: {
          ...state.feed,
          state: "success",
          items: [...state.feed.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_FEED_CLIPS_FAILURE:
      return {
        ...state,
        feed: {
          ...state.feed,
          state: "failure",
          has_more: false,
        },
      };
    case GET_MY_VTUBER_LIST_CLIPS_REQUEST:
      return {
        ...state,
        my_vtuber_list: {
          ...state.my_vtuber_list,
          state: "request",
        },
      };
    case GET_MY_VTUBER_LIST_CLIPS_SUCCESS:
      return {
        ...state,
        my_vtuber_list: {
          ...state.my_vtuber_list,
          state: "success",
          items: [...state.my_vtuber_list.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_MY_VTUBER_LIST_CLIPS_FAILURE:
      return {
        ...state,
        my_vtuber_list: {
          ...state.my_vtuber_list,
          state: "failure",
          has_more: false,
        },
      };
    case GET_TRENDING_CLIPS_REQUEST:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "request",
        },
      };
    case GET_TRENDING_CLIPS_SUCCESS:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "success",
          items: [...state.trending.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_TRENDING_CLIPS_FAILURE:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "failure",
          has_more: false,
        },
      };
    case GET_USERS_CLIPS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          state: "request",
        },
      };
    case GET_USERS_CLIPS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          state: "success",
          items: [...state.users.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_USER_LIKE_CLIPS_FAILURE:
      return {
        ...state,
        user_like: {
          ...state.user_like,
          state: "failure",
          has_more: false,
        },
      };
    case GET_USER_LIKE_CLIPS_REQUEST:
      return {
        ...state,
        user_like: {
          ...state.user_like,
          state: "request",
        },
      };
    case GET_USER_LIKE_CLIPS_SUCCESS:
      return {
        ...state,
        user_like: {
          ...state.user_like,
          state: "success",
          items: [...state.user_like.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_USERS_CLIPS_FAILURE:
      return {
        ...state,
        user_like: {
          ...state.user_like,
          state: "failure",
          has_more: false,
        },
      };
    case POST_CLIP_LIKE_REQUEST:
      return {
        ...state,
      };
    case POST_CLIP_LIKE_SUCCESS:
      return {
        ...state,
      };
    case POST_CLIP_LIKE_FAILURE:
      return {
        ...state,
      };
    case POST_CLIP_UNLIKE_REQUEST:
      return {
        ...state,
      };
    case POST_CLIP_UNLIKE_SUCCESS:
      return {
        ...state,
      };
    case POST_CLIP_UNLIKE_FAILURE:
      return {
        ...state,
      };
    case POST_CLIP_REQUEST:
      return {
        ...state,
        new: {
          ...state.new,
          state: "request",
        },
      };
    case POST_CLIP_SUCCESS:
      return {
        ...state,
        new: {
          ...state.new,
          clip: action.res.data,
          state: "success",
        },
      };
    case PUT_CLIP_REQUEST:
      return {
        ...state,
        state: "request",
      };
    case PUT_CLIP_FAILURE:
      return {
        ...state,
        state: "failure",
      };
    case PUT_CLIP_SUCCESS:
      return {
        ...state,
        ...action.res.data,
        state: "success",
      };
    case POST_CLIP_FAILURE:
      return {
        ...state,
        new: {
          ...state.new,
          state: "failure",
        },
      };
    case GET_CLIP_REQUEST:
      return {
        ...state,
        state: "request",
      };
    case GET_CLIP_SUCCESS:
      return {
        ...state,
        ...action.res.data,
        state: "success",
      };
    case GET_CLIP_FAILURE:
      return {
        ...state,
        state: "failure",
      };
    case RESET_CLIP:
      return {
        ...state,
        title: "",
        description: "",
        clip_loop_count: 0,
        clip_likes_count: 0,
        user: undefined,
        vtubers: [],
        clip_fragments: [],
      };
    case RESET_RELATED_CLIPS:
      return {
        ...state,
        related: {
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case RESET_TRENDING_CLIPS:
      return {
        ...state,
        trending: {
          ...state.trending,
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case RESET_FEED_CLIPS:
      return {
        ...state,
        feed: {
          ...state.feed,
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case RESET_VTUBER_GROUP_CLIPS:
      return {
        ...state,
        vtuber_group: {
          ...state.vtuber_group,
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case RESET_USERS_CLIPS:
      return {
        ...state,
        users: {
          ...state.users,
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case RESET_USER_LIKE_CLIPS:
      return {
        ...state,
        user_like: {
          ...state.user_like,
          state: "sleep",
          items: [],

          page: 0,
          length: 0,
          has_more: true,
          max_length: 0,
        },
      };
    case SET_TRENDING_CLIPS_ORDER:
      return {
        ...state,
        trending: {
          ...state.trending,
          order: action.order,
        },
      };
    case SET_VTUBER_GROUP_CLIPS_ORDER:
      return {
        ...state,
        vtuber_group: {
          ...state.vtuber_group,
          order: action.order,
        },
      };

    case SET_USERS_CLIPS_ORDER:
      return {
        ...state,
        users: {
          ...state.users,
          order: action.order,
        },
      };
    case SET_USER_LIKE_CLIPS_ORDER:
      return {
        ...state,
        user_like: {
          ...state.user_like,
          order: action.order,
        },
      };
    default:
      return { ...state };
  }
};
