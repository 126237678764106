import axios from "axios";
import config from "../config";
import { setAuthKeys } from "../auth";

export const GET_TRENDING_INTRODUCTIONS_REQUEST =
  "GET_TRENDING_INTRODUCTIONS_REQUEST";
export const GET_TRENDING_INTRODUCTIONS_SUCCESS =
  "GET_TRENDING_INTRODUCTIONS_SUCCESS";
export const GET_TRENDING_INTRODUCTIONS_FAILURE =
  "GET_TRENDING_INTRODUCTIONS_FAILURE";

export const GET_USER_INTRODUCTIONS_REQUEST = "GET_USER_INTRODUCTIONS_REQUEST";
export const GET_USER_INTRODUCTIONS_SUCCESS = "GET_USER_INTRODUCTIONS_SUCCESS";
export const GET_USER_INTRODUCTIONS_FAILURE = "GET_USER_INTRODUCTIONS_FAILURE";

export const GET_VTUBER_INTRODUCTIONS_REQUEST =
  "GET_VTUBER_INTRODUCTIONS_REQUEST";
export const GET_VTUBER_INTRODUCTIONS_SUCCESS =
  "GET_VTUBER_INTRODUCTIONS_SUCCESS";
export const GET_VTUBER_INTRODUCTIONS_FAILURE =
  "GET_VTUBER_INTRODUCTIONS_FAILURE";

export const GET_INTRODUCTION_REQUEST = "GET_INTRODUCTION_REQUEST";
export const GET_INTRODUCTION_SUCCESS = "GET_INTRODUCTION_SUCCESS";
export const GET_INTRODUCTION_FAILURE = "GET_INTRODUCTION_FAILURE";

export const GET_INTRODUCTION_VTUBER_DIRECT_REQUEST =
  "GET_INTRODUCTION_VTUBER_DIRECT_REQUEST";
export const GET_INTRODUCTION_VTUBER_DIRECT_SUCCESS =
  "GET_INTRODUCTION_VTUBER_DIRECT_SUCCESS";
export const GET_INTRODUCTION_VTUBER_DIRECT_FAILURE =
  "GET_INTRODUCTION_VTUBER_DIRECT_FAILURE";

export const POST_INTRODUCTION_REQUEST = "POST_INTRODUCTION_REQUEST";
export const POST_INTRODUCTION_SUCCESS = "POST_INTRODUCTION_SUCCESS";
export const POST_INTRODUCTION_FAILURE = "POST_INTRODUCTION_FAILURE";

export const PUT_INTRODUCTION_REQUEST = "PUT_INTRODUCTION_REQUEST";
export const PUT_INTRODUCTION_SUCCESS = "PUT_INTRODUCTION_SUCCESS";
export const PUT_INTRODUCTION_FAILURE = "PUT_INTRODUCTION_FAILURE";

export const DELETE_INTRODUCTION_REQUEST = "DELETE_INTRODUCTION_REQUEST";
export const DELETE_INTRODUCTION_SUCCESS = "DELETE_INTRODUCTION_SUCCESS";
export const DELETE_INTRODUCTION_FAILURE = "DELETE_INTRODUCTION_FAILURE";

export const POST_LIKE_REQUEST = "POST_LIKE_REQUEST";
export const POST_LIKE_SUCCESS = "POST_LIKE_SUCCESS";
export const POST_LIKE_FAILURE = "POST_LIKE_FAILURE";

export const POST_UNLIKE_REQUEST = "POST_UNLIKE_REQUEST";
export const POST_UNLIKE_SUCCESS = "POST_UNLIKE_SUCCESS";
export const POST_UNLIKE_FAILURE = "POST_UNLIKE_FAILURE";

export const SET_INTRODUCTION_STATE = "SET_INTRODUCTION_STATE";
export const SET_INTRODUCTION_DELETE_STATE = "SET_INTRODUCTION_DELETE_STATE";

export const SET_TRENDING_ORDER = "SET_TRENDING_ORDER";

export const RESET_USER_INTRODUCTIONS = "RESET_USER_INTRODUCTIONS";
export const RESET_VTUBER_INTRODUCTIONS = "RESET_VTUBER_INTRODUCTIONS";
export const RESET_TRENDING_INTRODUCTIONS = "RESET_TRENDING_INTRODUCTIONS";
export const RESET_INTRODUCTION = "RESET_INTRODUCTION";

export const reset_user_introductions = () => dispatch => {
  dispatch({ type: RESET_USER_INTRODUCTIONS });
};
export const reset_vtuber_introductions = () => dispatch => {
  dispatch({ type: RESET_VTUBER_INTRODUCTIONS });
};
export const reset_trending_introductions = () => dispatch => {
  dispatch({ type: RESET_TRENDING_INTRODUCTIONS });
};
export const reset_introduction = () => dispatch => {
  dispatch({ type: RESET_INTRODUCTION });
};

export const set_trending_order = order => dispatch => {
  dispatch({ type: SET_TRENDING_ORDER, order });
};

export const post_like = introduction_id => async dispatch => {
  dispatch({ type: POST_LIKE_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.post(
      `${config.backend_api_url}/forum/introductions/${introduction_id}/like`
    );
    return dispatch({ type: POST_LIKE_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: POST_LIKE_FAILURE });
  }
};

export const post_unlike = introduction_id => async dispatch => {
  dispatch({ type: POST_UNLIKE_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.post(
      `${config.backend_api_url}/forum/introductions/${introduction_id}/unlike`
    );
    return dispatch({ type: POST_UNLIKE_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: POST_UNLIKE_FAILURE });
  }
};

export const set_introduction_state = state => dispatch => {
  dispatch({ type: SET_INTRODUCTION_STATE, state });
};

export const set_introduction_delete_state = state => dispatch => {
  dispatch({ type: SET_INTRODUCTION_DELETE_STATE, state });
};

export const get_trending_introductions = page => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_TRENDING_INTRODUCTIONS_REQUEST });
  setAuthKeys();

  const { order } = getState().introduction.trending;

  try {
    const res = await axios.get(
      `${config.backend_api_url}/forum/introductions/trending?page=${page}&order=${order}`
    );
    return dispatch({ type: GET_TRENDING_INTRODUCTIONS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_TRENDING_INTRODUCTIONS_FAILURE });
  }
};

export const get_user_introductions = user_id => page => async dispatch => {
  dispatch({ type: GET_USER_INTRODUCTIONS_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/forum/introductions/users/${user_id}?page=${page}`
    );
    return dispatch({ type: GET_USER_INTRODUCTIONS_SUCCESS, res, page });
  } catch (e) {
    return dispatch({ type: GET_USER_INTRODUCTIONS_FAILURE });
  }
};

export const get_vtuber_introductions = vtuber_id => page => async (
  dispatch,
  getState
) => {
  const state = getState();
  const vtuber_introductions =
    state && state.introduction && state.introduction.vtuber_introductions;
  if (
    vtuber_introductions.state === "request" ||
    page <= vtuber_introductions.page
  ) {
    return;
  }

  dispatch({ type: GET_VTUBER_INTRODUCTIONS_REQUEST, vtuber_id, page });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/forum/introductions/vtubers/${vtuber_id}?page=${page}`
    );
    return dispatch({
      type: GET_VTUBER_INTRODUCTIONS_SUCCESS,
      res,
      page,
      vtuber_id
    });
  } catch (e) {
    return dispatch({ type: GET_VTUBER_INTRODUCTIONS_FAILURE });
  }
};

export const get_introduction = introduction_id => async dispatch => {
  dispatch({ type: GET_INTRODUCTION_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/forum/introductions/${introduction_id}`
    );
    return dispatch({ type: GET_INTRODUCTION_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: GET_INTRODUCTION_FAILURE });
  }
};

export const get_introduction_vtuber_direct = vtuber_id => async dispatch => {
  dispatch({ type: GET_INTRODUCTION_VTUBER_DIRECT_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.get(
      `${config.backend_api_url}/vtubers/${vtuber_id}`
    );
    return dispatch({ type: GET_INTRODUCTION_VTUBER_DIRECT_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: GET_INTRODUCTION_VTUBER_DIRECT_FAILURE });
  }
};

export const delete_introduction = introduction_id => async dispatch => {
  dispatch({ type: DELETE_INTRODUCTION_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.delete(
      `${config.backend_api_url}/forum/introductions/${introduction_id}`
    );
    return dispatch({ type: DELETE_INTRODUCTION_SUCCESS, res });
  } catch (e) {
    return dispatch({ type: DELETE_INTRODUCTION_FAILURE });
  }
};

export const post_introduction = ({
  favorite_point_description_0,
  favorite_point_description_1,
  favorite_point_description_2,
  youtube_video_description_0,
  youtube_video_description_1,
  youtube_video_description_2,
  youtube_video_id_0,
  youtube_video_id_1,
  youtube_video_id_2,
  vtuber_id
}) => async dispatch => {
  dispatch({ type: POST_INTRODUCTION_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.post(
      `${config.backend_api_url}/forum/introductions`,
      {
        introduction: {
          favorite_point_description_0,
          favorite_point_description_1,
          favorite_point_description_2,
          youtube_video_description_0,
          youtube_video_description_1,
          youtube_video_description_2,
          youtube_video_id_0,
          youtube_video_id_1,
          youtube_video_id_2,
          vtuber_id: vtuber_id
        }
      }
    );
    dispatch({ type: POST_INTRODUCTION_SUCCESS, res });
  } catch (e) {
    dispatch({ type: POST_INTRODUCTION_FAILURE });
  }
};

export const put_introduction = ({
  introduction_id,
  favorite_point_description_0,
  favorite_point_description_1,
  favorite_point_description_2,
  youtube_video_description_0,
  youtube_video_description_1,
  youtube_video_description_2,
  youtube_video_id_0,
  youtube_video_id_1,
  youtube_video_id_2,
  vtuber_id
}) => async dispatch => {
  dispatch({ type: PUT_INTRODUCTION_REQUEST });
  setAuthKeys();

  try {
    const res = await axios.put(
      `${config.backend_api_url}/forum/introductions/${introduction_id}`,
      {
        introduction: {
          favorite_point_description_0,
          favorite_point_description_1,
          favorite_point_description_2,
          youtube_video_description_0,
          youtube_video_description_1,
          youtube_video_description_2,
          youtube_video_id_0,
          youtube_video_id_1,
          youtube_video_id_2,
          vtuber_id: vtuber_id
        }
      }
    );
    dispatch({ type: PUT_INTRODUCTION_SUCCESS, res });
  } catch (e) {
    dispatch({ type: PUT_INTRODUCTION_FAILURE });
  }
};
