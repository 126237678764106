import {
  UPDATE_TAGS_REQUEST,
  UPDATE_TAGS_SUCCESS,
  UPDATE_TAGS_FAILURE,
  GET_POPULAR_VTUBER_TAGS_REQUEST,
  GET_POPULAR_VTUBER_TAGS_SUCCESS,
  GET_POPULAR_VTUBER_TAGS_FAILURE,
  GET_POPULAR_VIDEO_TAGS_REQUEST,
  GET_POPULAR_VIDEO_TAGS_SUCCESS,
  GET_POPULAR_VIDEO_TAGS_FAILURE,
} from "../actions/tag";

const initialState = {
  state: "sleep",

  popular_vtuber: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },

  popular_video: {
    state: "sleep",
    items: [],

    page: 0,
    length: 0,
    has_more: true,
    max_length: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TAGS_REQUEST:
      return { ...state, state: "request" };
    case UPDATE_TAGS_SUCCESS:
      return { ...state, state: "success" };
    case UPDATE_TAGS_FAILURE:
      return { ...state, state: "failure" };

    case GET_POPULAR_VTUBER_TAGS_REQUEST:
      return {
        ...state,
        popular_vtuber: {
          ...state.popular_vtuber,
          state: "request",
        },
      };
    case GET_POPULAR_VTUBER_TAGS_SUCCESS:
      return {
        ...state,
        popular_vtuber: {
          ...state.popular_vtuber,
          state: "success",
          items: [...state.popular_vtuber.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_POPULAR_VTUBER_TAGS_FAILURE:
      return {
        ...state,
        popular_vtuber: {
          ...state.popular_vtuber,
          state: "failure",
          has_more: false,
        },
      };

    case GET_POPULAR_VIDEO_TAGS_REQUEST:
      return {
        ...state,
        popular_video: {
          ...state.popular_video,
          state: "request",
        },
      };
    case GET_POPULAR_VIDEO_TAGS_SUCCESS:
      return {
        ...state,
        popular_video: {
          ...state.popular_video,
          state: "success",
          items: [...state.popular_video.items, ...action.res.data],
          has_more: action.res.data.length > 0,
          page: action.page,
        },
      };
    case GET_POPULAR_VIDEO_TAGS_FAILURE:
      return {
        ...state,
        popular_video: {
          ...state.popular_video,
          state: "failure",
          has_more: false,
        },
      };
    default:
      return { ...state };
  }
};
